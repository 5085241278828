import {Pipe, PipeTransform} from '@angular/core';
import {
    isVoid,
    makeFullExpirationDate,
    makeGuiFriendlyExpirationDate,
    makeGuiFriendlyExpirationDateDte
} from "../utils";

@Pipe({
    name: 'etsFriendlyDatePipe'
})

export class FriendlyDatePipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {

        if (isVoid(value)) {
            return value;
        }

        const isFull = args.length > 0 && args[0] === 'full';
        const isDte = args.length > 0 && args[0] === 'dte';
        const isDayOfWeek = args.length > 0 && args[1] === 'dayOfWeek';

        let result;

        if (isFull) {
            result = makeFullExpirationDate(value);
        } else if (isDte) {
            result = makeGuiFriendlyExpirationDateDte(value);
        } else {
            result =  makeGuiFriendlyExpirationDate(value);
        }

        return result;
    }
}


