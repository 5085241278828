import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../../../utils";
import {HedgeItemToTrack} from "../../../../shell-communication/shell-dto-protocol";

@Component({
    selector: 'ets-hedges-total-sweet-price',
    template: `
      <fieldset class="by-expiration-block">
        <legend class="by-expiration-title">
          {{ this.hedgeTrackingGroup?.header }}
        </legend>
        
        <ng-container *ngIf="this.global">
          <div class="global inputs">
            <div class="total hedge" *ngFor="let item of this.hedgeTrackingGroup?.totals">
              <div class="checkbox">
                <dx-check-box [(value)]="item.enabled"
                              (onValueChanged)="this.hedgeTrackingGroup?.onCheckboxToggled(item)">
                </dx-check-box>
              </div>
              <div class="label">
                {{ item.label }}
              </div>
              <div class="price">
                <dx-number-box width="100%"
                               [disabled]="!item.enabled"
                               [(value)]="item.sweetPrice" 
                               (onValueChanged)="this.onChange()"
                               format="$#0.00"
                               [inputAttr]="{style: 'text-align: center;'}"
                ></dx-number-box>
              </div>
            </div>
          </div>
        </ng-container>
        
        <ng-container *ngIf="!this.global">
          
          <div class="inputs">
            
            <div class="group totals">
              <div class="header">
                Totals
              </div>
              <div class="individuals">
                <div class="total hedge" *ngFor="let item of this.hedgeTrackingGroup?.totals">
                  <div class="checkbox">
                    <dx-check-box [(value)]="item.enabled"
                                  (onValueChanged)="this.hedgeTrackingGroup?.onCheckboxToggled(item)">
                    </dx-check-box>
                  </div>
                  <div class="label">
                    {{ item.label }}
                  </div>
                  <div class="price">
                    <dx-number-box width="100%"
                                   [disabled]="!item.enabled"
                                   [(value)]="item.sweetPrice"
                                   (onValueChanged)="this.onChange()"
                                   format="$#0.00"
                                   [inputAttr]="{style: 'text-align: center;'}"
                    ></dx-number-box>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="group calls">
              <div class="header">
                Calls
              </div>
              <div class="individuals">
                <div class="hedge" *ngFor="let item of this.hedgeTrackingGroup?.calls">
                  <div class="checkbox">
                    <dx-check-box [(value)]="item.enabled"
                                  (onValueChanged)="this.hedgeTrackingGroup?.onCheckboxToggled(item)">
                    </dx-check-box>
                  </div>
                  <div class="label" [style.color]="item.color">
                    {{ item.label }}
                  </div>
                  <div class="price">
                    <dx-number-box width="100%"
                                   [disabled]="!item.enabled"
                                   [(value)]="item.sweetPrice"
                                   (onValueChanged)="this.onChange()"
                                   format="$#0.00"
                                   [inputAttr]="{style: 'text-align: center;'}"
                    ></dx-number-box>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="group puts">
              <div class="header">
                Puts
              </div>
              <div class="individuals">
                <div class="hedge" *ngFor="let item of this.hedgeTrackingGroup?.puts">
                  <div class="checkbox">
                    <dx-check-box [(value)]="item.enabled"
                                  (onValueChanged)="this.hedgeTrackingGroup?.onCheckboxToggled(item)">
                    </dx-check-box>
                  </div>
                  <div class="label" [style.color]="item.color">
                    {{ item.label }}
                  </div>
                  <div class="price">
                    <dx-number-box width="100%"
                                   [disabled]="!item.enabled"
                                   [(value)]="item.sweetPrice"
                                   (onValueChanged)="this.onChange()"
                                   format="$#0.00"
                                   [inputAttr]="{style: 'text-align: center;'}"
                    ></dx-number-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      
      </fieldset>
    `,
    styles: [`

        .global .hedge {
            flex: 1;
        }

        .global .hedge .label {
            font-weight: 800;
        }

        .by-expiration-block {
            margin-bottom: 15px;
        }

        .by-expiration-title {
            font-size: 1.1em;
            font-weight: bold;
        }

        .inputs {
            display: flex;
            column-gap: 10px;
            align-items: flex-start;
            justify-content: center;
            padding: 5px 0;
        }

        .group {
            flex: 10;
        }

        .group .header {
            text-align: center;
            font-weight: 800;
            font-size: 1.2em;

            border-bottom: 1px solid white;
            width: 80%;
            margin: auto auto 10px;
            padding-bottom: 5px;
        }

        .hedge {
            display: flex;
            column-gap: 5px;

            .label {
                flex: 1;
            }

            .price {
                flex: 0;
                min-width: 65px;
                max-width: 65px;
            }

            margin-bottom: 3px;

            align-items: center;
        }

        .individuals {
            display: flex;
            flex-direction: column;
        }

    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HedgesTotalSweetPriceComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
    ) {
    }

    private _hedgeTrackingGroup: HedgeTrackingGroup;
    get hedgeTrackingGroup(): HedgeTrackingGroup {
        return this._hedgeTrackingGroup;
    }

    @Input()
    set hedgeTrackingGroup(value: HedgeTrackingGroup) {
        this._hedgeTrackingGroup = value;
        if (this._hedgeTrackingGroup) {
            this._hedgeTrackingGroup.somethingChanged$.subscribe(() => {
                this.onChange();
            });
        }
    }

    @Input() global: boolean;

    ngOnInit() {
    }

    @DetectMethodChanges()
    onChange() {
    }
}


export class HedgeTrackingGroup {

    constructor(
        public readonly expiration?: string,
    ) {
        this.totals = [
            {
                label: 'Calls',
                aggregationType: !expiration ? 'global' : 'expiration',
                expiration: expiration,
                itemType: 'aggregate',
                optionType: 'Call',
                enabled: false
            },
            {
                label: 'Puts',
                aggregationType: !expiration ? 'global' : 'expiration',
                expiration: expiration,
                itemType: 'aggregate',
                optionType: 'Put',
                enabled: false
            },
            {
                label: 'Total',
                aggregationType: !expiration ? 'global' : 'expiration',
                expiration: expiration,
                itemType: 'aggregate',
                optionType: 'Total',
                enabled: false
            },
        ];
    }



    somethingChanged$ = new EventEmitter();

    header: string;

    totals: HedgeItemToTrack[] = [];

    calls: HedgeItemToTrack[] = [];

    puts: HedgeItemToTrack[] = [];

    onCheckboxToggled(item: HedgeItemToTrack) {
        if (!item.enabled) {
            item.sweetPrice = null;
        }
        this.onChange();
    }

    onChange() {
        this.somethingChanged$.emit();
    }

    getItemsToTrack(): HedgeItemToTrack[] {
        const totals = this.totals.filter(x => x.enabled);
        const calls = this.calls.filter(x => x.enabled);
        const puts = this.puts.filter(x => x.enabled);

        const hedgeItemToTracks = totals.concat(calls).concat(puts);

        return hedgeItemToTracks;
    }
}
