import { Component } from '@angular/core';
import {IHeaderGroupAngularComp} from "ag-grid-angular";
import {IHeaderGroupParams} from "ag-grid-community";
import {HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {isVoid, makePortfolioKey} from "../../utils";

@Component({
    selector: 'ets-hedge-matrix-colgroup-header',
    template: `
        <div class="ag-header-group-cell-label" 
             [class.ets-positive-number]="this.cost > 0"
             [class.ets-negative-number]="this.cost < 0"
             [class.grand-totals]="this.side === 'grand-totals-all'"
        >
          {{this.cost | currency:'USD':'symbol':'1.2-2' }}
        </div>
    `,
    styles: [
        `
            .ag-header-group-cell-label {
                justify-content: center;
                align-items: center;
                justify-self: center;
                text-align: center;
                font-weight: bold;
            }
            
            .grand-totals {
                font-size: 1.3em;
            } 
        `
    ]
})
export class HedgeMatrixCustomColumnHeaderGroup implements IHeaderGroupAngularComp {

    constructor() {
    }

    get cost() : number {
       if (isVoid(this.expiration)) {
           return this.getSideCost();
       }

       return this.getExpirationCost();
    }

    params!: IHeaderGroupParams;

    comp: HgHedgeMatrixComponent;

    side: string;

    expiration: string;

    allInclusive: boolean;

    agInit(params: IHeaderGroupParams): void {
        this.params = params;

        this.comp = params.context.comp;

        this.side = params.context.side;

        this.expiration = params.context.expiration;

        this.allInclusive = params.context.allInclusive;

        if (isVoid(this.expiration)) {
            if (!this.side.startsWith('grand-totals')) {
                this.side = params.context.side + 's';
            }
        }
    }

    refresh() {
        return true;
    }

    private getSideCost() {
        if (isVoid(this.comp)) {
            return undefined;
        }

        const pfKey = makePortfolioKey(this.comp.selectedPortfolio);
        if (!this.side.startsWith('grand-totals')) {
            const side = this.side.toLowerCase();
            const px = this.comp.hedgesPricingService.getHedgesCost(pfKey, side as any);
            return px;
        } else if (this.side === 'grand-totals-all') {
            const calls = this.comp.hedgesPricingService.getHedgesCost(pfKey, 'calls');
            const puts = this.comp.hedgesPricingService.getHedgesCost(pfKey, 'puts');
            const sum = calls + puts;
            return sum;
        }
    }

    private getExpirationCost() {

        if (this.side === 'grand-totals') {
            const hedgeIds = this.comp
                .hedgeMatrixDataService
                .getHedges()
                .filter(x => x.expiration === this.expiration);
            const pnls = hedgeIds.map(x => this.comp.getHedgePrice(x));
            const sum = pnls.reduce((a, b) => a + b, 0);
            return sum;
        }

        const hedgeIds = this.comp
            .hedgeMatrixDataService
            .getHedges()
            .filter(x =>  x.expiration === this.expiration)
            .filter(x => this.side === x.type);

        const pnls = hedgeIds.map(x => this.comp.getHedgePrice(x));

        const sum = pnls.reduce((a, b) => a + b, 0);

        return sum;

    }
}