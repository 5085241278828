import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { TimestampsService } from '../timestamps.service';
import {DetectMethodChanges, isVoid} from '../utils';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-test-panel',
   templateUrl: 'test-panel.component.html',
   styleUrls: ['test-panel.component.scss'],
})

export class TestPanelComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _timestampsService: TimestampsService
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   draggedItem: any;
   
   tasks: { text: string }[] = [
      { text: 'Task A' },
      { text: 'Task B' },
      { text: 'Task C' },
      { text: 'Task D' },
   ];

   ngOnInit() {

      // sortableList.addEventListener(
      //     "dragstart",
      //     (e) => {
      //        setTimeout(() => {
      //           e.target.style.display =
      //               "none";
      //        }, 0);
      //     });

      // sortableList.addEventListener(
      //     "dragend",
      //     (e) => {
      //        setTimeout(() => {
      //           e.target.style.display = "";
      //           draggedItem = null;
      //        }, 0);
      //     });

      // sortableList.addEventListener(
      //     "dragover",
      //     (e) => {
      //        e.preventDefault();
      //        const afterElement =
      //            getDragAfterElement(
      //                sortableList,
      //                e.clientY);
      //        const currentElement =
      //            document.querySelector(
      //                ".dragging");
      //        if (afterElement == null) {
      //           sortableList.appendChild(
      //               draggedItem
      //           );}
      //        else {
      //           sortableList.insertBefore(
      //               draggedItem,
      //               afterElement
      //           );}
      //     });

      const getDragAfterElement = (
          container, y
      ) => {
         const draggableElements = [
            ...container.querySelectorAll(
                "li:not(.dragging)"
            ),];

         return draggableElements.reduce(
             (closest, child) => {
                const box =
                    child.getBoundingClientRect();
                const offset =
                    y - box.top - box.height / 2;
                if (
                    offset < 0 &&
                    offset > closest.offset) {
                   return {
                      offset: offset,
                      element: child,
                   };}
                else {
                   return closest;
                }},
             {
                offset: Number.NEGATIVE_INFINITY,
             }
         ).element;
      };
   }

   
   //#region base interface

   // @DetectMethodChanges()
   etsOnInit(): void {

   }

   etsOnDestroy(): void {
   }

   etsAfterViewInit(): void {
      this._changeDetector.detach();
   }

   protected getState() {
      return null;
   }

   protected setState(state: any) {
   }
   
   //#endregion

   onItemDragStart(e: any) {
      console.log('onItemDragStart', e);
      this.draggedItem = e.target;
   }

   @DetectMethodChanges()
   onItemDragEnd(e: any) {
      console.log('onItemDragEnd', e);

      this.draggedItem = null;

      // if (isVoid(e.fromData)) {
      //    e.cancel = true;
      //    return;
      // }
      //
      // if (isVoid(e.toData)) {
      //    e.cancel = true;
      //    return;
      // }
      //
      // const fromIx = this.tasks.indexOf(e.fromData);
      //
      // let toIx = this.tasks.indexOf(e.toData);
      //
      // // if (fromIx > toIx) {
      // //    toIx -= 1;
      // // }
      //
      // if (toIx < 0) { toIx = 0;}
      //
      // if (toIx > this.tasks.length - 1) {toIx = this.tasks.length - 1;}
      //
      // const tasks = this.tasks.filter(x => x !== e.fromData);
      //
      // tasks.splice(toIx, 0, e.fromData);
      //
      // this.tasks = tasks;
   }
}
