// noinspection DuplicatedCode

import {ChangeDetectorRef, EventEmitter} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AccessControlService} from "projects/shared-components/access-control-service.class";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {AtmStrikeService} from "../../../common-services/atm-strike-service/atm-strike.service";
import {
    CashFlowStrategyRole,
    OptionExpirationDescriptor
} from "projects/shared-components/shell-communication/shell-dto-protocol";
import {TradingInstrument} from "projects/shared-components/trading-instruments/trading-instrument.class";
import {
    delay,
    DetectMethodChanges,
    DetectSetterChanges,
    getBucketRoleClass as getBucketRoleClass,
    isReversedCashFlowOrder,
    isValidNumber,
    isVoid,
    numberCompare
} from "projects/shared-components/utils";
import {isNullOrUndefined} from "util";
import {BeforePositionDto} from "../../model/BeforePositionDto";
import {DefaultsMemento, PositionsDefaultsPopupModel} from "./BucketBeforeStateDefaultsPopupModel";
import {BeforePositionModel} from "./BeforePositionModel";
import {PositionsData} from "./PositionsData";
import * as Enumerable from "linq";
import {LocationService} from "projects/shared-components/location.service";
import {SessionService} from "projects/shared-components/authentication/session-service.service";
import {Subject} from "rxjs";
import {
    ApplicationSettingsService,
    AppSettingsUpdatedMessageTopic
} from "projects/shared-components/app-settings/application-settings.service";
import {MessageBusService} from "projects/shared-components/message-bus.service";
import {filter, takeUntil} from "rxjs/operators";
import {
    GetPositionsDataRequest,
    PositionsDefaultSettings,
    PositionsDefaultsProvider,
    PositionsStateService
} from "../../services/positions-before-state.service";
import {SavedPositionsService} from "../../services/saved-positions.service";
import {ApgPortfolio} from "../../model/ApgPortfolio";
import {
    TradingInstrumentsService
} from "projects/shared-components/trading-instruments/trading-instruments-service.interface";
import {PositionsAfterStateService} from "../../services/positions-after-state.service";
import {SolutionPositionDto} from "../../model/SolutionPositionDto";
import {ApgAfterStatePreviewComponent} from "../../after-state-preview/apg-after-state-preview.component";
import {AdjustmentPricingSettingsDto} from "projects/shared-components/shell-communication/shell-operations-protocol";
import {ItemClickEvent} from "devextreme/ui/drop_down_button";
import {ApgTrackingDialogComponent, SweetPricePopupConfig} from "../tracking-dialog/apg-tracking-dialog.component";
import {OptionsChainService} from "projects/shared-components/option-chains.service";
import {parseOptionTicker} from "projects/shared-components/options-common/options.model";
import {ICashFlowAdjustmentSettingsTemplate} from "../../model/ICashFlowAdjustmentSettingsTemplate";
import {ApgContextMenuComponent} from "../../context-menu/apg-context-menu.component";
import {UpdateOrderQtyPromptModel} from "./UpdateOrderQtyPromptModel";
import {DxNumberBoxComponent} from "devextreme-angular/ui/number-box";
import {ExpirationSmartModeSettings} from "../../../app-settings/model/ExpirationSmartModeSettings";
import {UserSettingsService} from "../../../user-settings.service";

const StrikesShiftMessageTopic = 'AdjustmentPricingGridStrikesShift';

type CtxMenuItems = 'Copy' | 'Paste' | 'Copy With Settings' | 'Paste Positions & Settings';

interface StrikesChangedMessage {
    event: 'step' | 'shift';
    side: 'left' | 'right';
    shiftDirection: 'up' | 'down';
    strikeStep: number;
    underlying: string;
}

export interface PositionsRestoredEventArgs {
    hasSpreadFirst: boolean;
    hasSpreadSecond: boolean;
    hasSecondSpreadFirst: boolean;
    hasSecondSpreadSecond: boolean;
    hasSecondProtectiveOptionFirst: boolean;
    hasSecondProtectiveOptionSecond: boolean;
}

interface ContextDataProvider {
    onLongRunningOperationChanged(x: boolean): void;
    applyClicked(x?: any): Promise<void>;
    toggleCollapsed(x: 'positions'): void;
    onPositionsRestored(x: any): void;
    onStartTrackingRequest(): Promise<void>;
    onStopTrackingRequest(): Promise<void>;
    onSweetPriceSettingsRequest(x: any): void;
    onSweetPriceSyncRequest(): Promise<void>;
    sweetPriceIsSync(): boolean;
    sweetPriceIsTracking(): boolean;
    getContextUserId(): string;
    onSweetPriceSettingsUpdated(): Promise<void>;
    tryApplyChangesLive(): Promise<void>;
    isDynamicOffsetEnabled(): boolean;
    getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate;
    pastePositions(positionsData: PositionsData[]): void;
    copyPositionsAndSettings(): void;
    pastePositionsAndSettings(): Promise<void>
    onTemplateApplied(): void;
}

export class PositionsSectionModel {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _unsubscriber: Subject<void>,
        private readonly _positionsBeforeStateService: PositionsStateService,
        private readonly _canApplyChangesCallback: () => boolean,
        private readonly _accessControlService: AccessControlService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _toastr: ToastrService,
        locationService: LocationService,
        private readonly _sessionService: SessionService,
        private readonly _applicationSettingsService: ApplicationSettingsService,
        private readonly _savedPositionsService: SavedPositionsService,
        private readonly _messageBus: MessageBusService,
        private readonly _scopeId: string,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _positionsAfterStateService: PositionsAfterStateService,
        private readonly _userSettingsService: UserSettingsService
    ) {
        const items = [];

        for (let index = 0; index < 100000; index++) {
            items.push(index);
        }
        this.strikesList = items;

        this.defaultSettingsPopup = new PositionsDefaultsPopupModel(
            _changeDetector,
            _unsubscriber,
            _positionsBeforeStateService,
            _toastr,
            locationService,
            _sessionService,
            _accessControlService
        );

        this.defaultSettingsPopup.defaultOrderQtyUpdated$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(updatedDefaults => this.onOrderDefaultSettingsUpdated(updatedDefaults));

        this.updatePositionQtyPrompt = new UpdateOrderQtyPromptModel(this._changeDetector);

        this._messageBus.of<StrikesChangedMessage>(StrikesShiftMessageTopic)
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(msg => this.onStrikesChanged(msg.payload));

        this._messageBus.of(AppSettingsUpdatedMessageTopic)
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(_ => this._changeDetector.detectChanges());

        this._positionsAfterStateService.afterStateUpdated$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(_ => this.updatePositionsAfterStateStatus());

        this._messageBus.of('Pkg-Cmprsn.AfterStateSelected')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(_ => this.updatePositionsAfterStateStatus());

        this._userSettingsService.userSettingChanged$
            .pipe(filter(x => x.key.startsWith('apg.positions.after.')))
            .subscribe(x => this.updatePositionsAfterStateStatus());

        this._messageBus.of<{hasExtraPos?: boolean}>('Resources.HasExtraPos')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {

                x.payload.hasExtraPos = false;

               if (isVoid(this.positionsData)) {
                   return;
               }

               const po = this.positionsData[0].positions.find(x => x.role === 'ProtectiveOption');
               const po2 = this.positionsData[0].positions.find(x => x.role === 'SecondProtectiveOption');
               const so = this.positionsData[0].positions.find(x => x.role === 'ShortOption');

               if (Math.abs(so.qty) !== Math.abs(po.qty)) {
                   x.payload.hasExtraPos = true;
               }

               if (!isVoid(po2)) {
                   if (Math.abs(so.qty) !== Math.abs(po2.qty)) {
                       x.payload.hasExtraPos = true;
                   }
               }
            });

        this._messageBus.of<{asset?: string}>('Resources.Asset')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {
                x.payload.asset = this.underlying;
            });
    }

    private _contextDataProvider: ContextDataProvider;

    private _selectedStrategy: CashFlowStrategy;

    private _temporaryState: PositionsDefaultsProvider;

    private _selectedPortfolio: ApgPortfolio;

    positionsChanged$ = new EventEmitter();

    defaultsUpdated$ = new EventEmitter<{ memento: DefaultsMemento, generateOrders?: boolean }>();

    orientation: 'left' | 'right';

    afterStatePreview: ApgAfterStatePreviewComponent;

    trackingDialog: ApgTrackingDialogComponent;

    tradingInstrument: TradingInstrument;

    atmDistance: number;

    lockParameters = true;

    ctxMenu: ApgContextMenuComponent;

    manualDistanceInput: DxNumberBoxComponent;

    private _showAtmTextBox: boolean;
    get showAtmTextBox(): boolean {
        return this._showAtmTextBox;
    }

    @DetectSetterChanges()
    set showAtmTextBox(value: boolean) {
        this._showAtmTextBox = value;
        if (this._showAtmTextBox) {
            this.focusManualAtmDistanceInput();
        }
    }

    get manualAtmDistanceDisplay(): string {
        return this.showAtmTextBox ? undefined : 'none';
    }

    readonly ctxMenuItems: { text: CtxMenuItems }[] = [{text: 'Copy'}];

    private _isForceApply: boolean;
    get isForceApply(): boolean {
        return this._isForceApply;
    }

    @DetectSetterChanges()
    set isForceApply(v: boolean) {
        this._isForceApply = v;
    }

    private _allowApply = false;

    get isDoubleMode(): boolean {
        return this._selectedStrategy === 'Calls & Puts';
    }

    get arePositionsLoaded(): boolean {
        return this.isPortfolioLoaded && !isVoid(this.positionsData);
    }

    get isPortfolioLoaded(): boolean {
        return !isVoid(this.selectedPortfolioId);
    }

    get isDynamicOffsetEnabled(): boolean {
        const available = this._accessControlService.isSecureElementAvailable(
            '59a7aa3f-46d1-4ce1-a826-1658d319f2e9'
        );
        return available && this._contextDataProvider.isDynamicOffsetEnabled();
    }

    private _hasAfterStateFirst: boolean;
    get hasAfterStateFirst(): boolean {
        return this._hasAfterStateFirst;
    }

    set hasAfterStateFirst(value: boolean) {
        this._hasAfterStateFirst = value;
    }

    private _hasAfterStateSecond: boolean;
    get hasAfterStateSecond(): boolean {
        return this._hasAfterStateSecond;
    }

    set hasAfterStateSecond(value: boolean) {
        this._hasAfterStateSecond = value;
    }

    afterStateTooltipFirst: String;

    afterStateTooltipSecond: String;

    get hasAfterState(): boolean {
        return this._hasAfterStateFirst || this._hasAfterStateSecond;
    }

    private _spreadFirstEnabled = true;
    get spreadFirstEnabled() {
        return this._spreadFirstEnabled;
    }

    set spreadFirstEnabled(value) {

        if (this._spreadFirstEnabled === value) {
            return;
        }

        this._spreadFirstEnabled = value;

        setTimeout(() => {
            const strategy = this._selectedStrategy === 'Calls & Puts'
                ? 'Calls'
                : this._selectedStrategy;
            this.onSpreadChanged(strategy)
                .catch(e => console.error(e));
        }, 0);

    }

    private _spreadSecondEnabled = true;
    get spreadSecondEnabled() {
        return this._spreadSecondEnabled;
    }

    set spreadSecondEnabled(value) {

        if (this._spreadSecondEnabled === value) {
            return;
        }

        this._spreadSecondEnabled = value;

        setTimeout(() => {
            this.onSpreadChanged('Puts').catch(e => {
                console.error(e);
            });
        }, 0);
    }

    get isSpreadFirstToggleEnabled(): boolean {
        return !isVoid(this.positionsData)
            && (this.positionsData[0].positions.some(y => y.role.startsWith('Second'))
                || !this.positionsData[0].positions.some(y => y.role.startsWith('Spread')));
    }

    get isSpreadSecondToggleEnabled(): boolean {
        return !isVoid(this.positionsData)
            && this.positionsData.length > 1
            && (this.positionsData[1].positions.some(y => y.role.startsWith('Second'))
                || !this.positionsData[1].positions.some(y => y.role.startsWith('Spread')));
    }

    get spreadFirstToggleLabel(): string {

        if (this._selectedStrategy === 'Calls & Puts') {
            return 'CS';
        }
        const isReversed = isReversedCashFlowOrder(this._selectedStrategy);
        return isReversed ? 'CS' : 'PS';
    }

    get showSpreadSecondToggle(): boolean {
        return this._selectedStrategy === 'Calls & Puts'
            && this.isToggleDebitSpreadAvailable;
    }

    get showSecondStateToggle(): boolean {
        return this._selectedStrategy === 'Calls & Puts'
            && this.isToggleStateButtonAvailable;
    }

    private _savingPositions = true;
    get savingPositions() {
        return this._savingPositions;
    }

    set savingPositions(value) {
        this._savingPositions = value;

        if (value) {
            this.savePositions();
        } else {
            this.clearSavedPositions();
        }
    }

    defaultSettingsPopup: PositionsDefaultsPopupModel;

    updatePositionQtyPrompt: UpdateOrderQtyPromptModel;

    manualAtmDistance: number;

    get savedStateLoaded(): boolean {
        return isVoid(this.positionsData)
            ? false
            : this.positionsData[0].isSavedState;
    }

    get isStrikesControlAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('4db8f26b-a70b-4869-988b-d9abc542baf2');
    }

    get isStrikeStepAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('882db897-2dfc-4ab7-b887-340e8334dbd9');
    }

    get isStrikesShiftButtonsAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('1baab2ff-efd8-48f3-9181-d5c80ed154e9');
    }

    get isAtmDistanceIndicatorAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('67a5f133-5ccf-4e8c-a486-4966e9d2dcb5');
    }

    get isLiveUpdateAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('2b866f70-195b-48a0-b7b9-188bece8af38');
    }

    get isTemporaryParametersLockAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('5218c380-5937-4da1-9574-40e9bb59ef90');
    }

    get isBeforePositionsDefaultsAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('0083b117-3e09-4698-98c1-c11252b7c621');
    }

    get isSavePositionsAsDefaultsAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('21a73754-308b-455a-8835-e0b486031ecf');
    }

    get isSavePositionsAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('8fd2b3bc-bfed-4e45-bf47-2a2815e1e77a');
    }

    get isToggleDebitSpreadAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('88008d2e-96cc-45d2-9862-a650914deebb');
    }

    get isToggleStateButtonAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('ea30c927-dd53-4d54-9140-c9131195eb98');
    }

    get strikeStepVisibility(): string {
        return this.isStrikeStepAvailable
            ? 'visible'
            : 'hidden';
    }

    get strikesShiftButtonsVisibility(): string {
        return this.isStrikesShiftButtonsAvailable
            ? 'visible'
            : 'hidden';
    }

    get atmDistanceIndicatorVisibility(): string {
        return this.isAtmDistanceIndicatorAvailable
            ? 'visible'
            : 'hidden';
    }

    get lockParametersVisibility(): string {
        return this.isTemporaryParametersLockAvailable
            ? 'visible'
            : 'hidden';
    }

    get savePositionsVisibility(): string {
        return this.isSavePositionsAvailable
            ? 'visible'
            : 'hidden';
    }

    get defaultsPopupVisibility(): string {
        return this.isBeforePositionsDefaultsAvailable
            ? 'visible'
            : 'hidden';
    }

    get saveAsDefaultButtonVisibility(): string {
        return this.isSavePositionsAsDefaultsAvailable
            ? 'visible'
            : 'hidden';
    }

    get toggleSpreadsVisibility(): string {
        return this.isToggleDebitSpreadAvailable
            ? 'visible'
            : 'hidden';
    }

    get strategyLetterForPositionAfterStateFirst(): string {
        if (this._selectedStrategy === 'Calls & Puts') {
            return 'C';
        }

        if (this._selectedStrategy === 'Calls') {
            return 'C';
        }

        if (this._selectedStrategy === 'Puts') {
            return 'P';
        }

        if (this._selectedStrategy === 'Hedged Portfolio') {
            return 'HP';
        }

        if (this._selectedStrategy === 'Reversed Hedged Portfolio') {
            return 'rHP';
        }

        return '--';
    }

    get isFullResetButtonAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('14c5e9d0-27e6-4207-bea3-34148088a33b');
    }

    get isClearSavedAdjustmentsButtonAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('cf0f3f94-e069-4e50-99a3-2400f9566d7b');
    }

    get isTrackButtonAvailable(): boolean {
        return this._accessControlService.isSecureElementAvailable('b9c6f9ae-f348-4f3d-a1c4-de645dc6de07');
    }

    get fullResetButtonVisibility(): string {
        return this.isFullResetButtonAvailable && this.isPortfolioLoaded
            ? 'visible'
            : 'hidden';
    }

    get clearSavedAdjustmentsButtonVisibility(): string {
        return this.arePositionsLoaded && this.isClearSavedAdjustmentsButtonAvailable
            ? 'visible'
            : 'hidden';
    }

    get trackButtonVisibility(): string {
        return this.arePositionsLoaded && this.isTrackButtonAvailable
            ? 'visible'
            : 'hidden';
    }

    get savePositionsCanChange(): boolean {
        const isAvailable = this._accessControlService
            .isSecureElementAvailable('0efde518-58b0-44ef-8960-33449247d67a');

        return isAvailable;
    }

    readonly strikesList: number[];

    private _hasChanges: boolean;
    get hasChanges(): boolean {
        return this._hasChanges && this.canChangeStrikes;
    }

    @DetectSetterChanges()
    set hasChanges(value: boolean) {

        this._hasChanges = value;

        if (value) {
            try {
                this._temporaryState = this.captureTemporaryState();
            } catch {
                //
            }
        }

    }

    get isApplyButtonDisabled(): boolean {
        return !this._canApplyChangesCallback()
            || isVoid(this.positionsData);
    }

    get isLinkStrikesEnabled(): boolean {
        return !isVoid(this.orientation) && this._applicationSettingsService.adjustmentPricingGrid.linkStrikes;
    }

    strikeStep: number;

    overrideAtmLiveMode : 'market' | 'custom' = undefined;

    private _liveStrikesUpdate = false;
    get liveStrikesUpdate(): boolean {
        return this._liveStrikesUpdate;
    }

    @DetectSetterChanges()
    set liveStrikesUpdate(v: boolean) {
        this._liveStrikesUpdate = v;
        if (this.hasChanges) {
            setTimeout(async () => {
                await this._contextDataProvider.applyClicked();
            }, 0);
        }

        if (!v) {
            this.overrideAtmLiveMode = undefined;
        }
    }

    get canChangeStrikes(): boolean {
        return this.positionsData && this.positionsData.length > 0;
    }

    get underlying(): string {
        return this.tradingInstrument ? this.tradingInstrument.ticker : null;
    }

    private get selectedPortfolioId(): string {
        return this._selectedPortfolio ? this._selectedPortfolio.id : undefined;
    }

    private _positionsData: PositionsData[];
    get positionsData(): PositionsData[] {
        return this._positionsData;
    }

    set positionsData(value: PositionsData[]) {

        this._positionsData = value;

        if (this.savingPositions) {
            if (!isVoid(value)) {
                this.savePositions();
            }
        }

        setTimeout(() => this.rankExpirations());
    }

    get trackMenuItems(): any[] {
        const result = [];

        result.push({id: 'settings', title: 'Settings', icon: 'preferences'});

        if (this._selectedPortfolio.userId !== this._sessionService.sessionData.userId) {
            return result;
        }

        if (this.isTracking) {

            if (this.isOutOfSync) {
                // noinspection SpellCheckingInspection
                result.unshift({id: 'sync', title: 'Sync', icon: 'pulldown'});
            }

            result.unshift({id: 'stop', title: 'Stop', icon: 'square'});

        } else {

            // noinspection SpellCheckingInspection
            result.unshift({id: 'start', title: 'Start', icon: 'spinnext'});

        }

        return result;
    }

    get isTracking(): boolean {
        return this._contextDataProvider.sweetPriceIsTracking();
    }

    get isOutOfSync(): boolean {
        const result = this._contextDataProvider.sweetPriceIsSync();
        return !result;
    }

    changeStrikeStep(n: number, sign: string, underlying: string) {

        let v = this.strikeStep || 0;

        if (underlying === 'SPX') {
            switch (n) {
                case 1:
                    n = 5;
                    break;
                case 5:
                    n = 10;
                    break;
                case 10:
                    n = 50;
                    break;
                case 50:
                    n = 100;
                    break;
            }
        }

        if (sign === '+') {
            v += n;
        } else {
            v -= n;
        }

        if (v < 0) {
            v = 0;
        }

        this.strikeStep = v;

        if (this._applicationSettingsService.adjustmentPricingGrid.linkStrikes) {
            this._messageBus.publishAsync({
                topic: StrikesShiftMessageTopic,
                payload: {
                    event: 'step',
                    strikeStep: v,
                    underlying: this.underlying,
                    side: this.orientation
                } as StrikesChangedMessage
            });
        }


        this._changeDetector.detectChanges();
    }

    @DetectMethodChanges({isAsync: true})
    async strikeUp(step?: number, notify = true): Promise<void> {

        if (!isValidNumber(step)) {
            step = this.strikeStep;
        }

        if (isVoid(step)) {
            return;
        }

        await this.shiftStrikes('up', step, notify);
    }

    @DetectMethodChanges({isAsync: true})
    async strikeDown(step?: number, notify = true): Promise<void> {

        if (!isValidNumber(step)) {
            step = this.strikeStep;
        }

        if (isVoid(step)) {
            return;
        }

        await this.shiftStrikes('down', step, notify);
    }

    private async shiftStrikes(shiftDirection: 'up' | 'down', strikeStep: number, notify = true): Promise<void> {
        this._contextDataProvider.onLongRunningOperationChanged(true);
        try {

            await this.shiftStrikesInternal(shiftDirection, strikeStep, notify);
            this.hasChanges = true;
        } finally {
            this._contextDataProvider.onLongRunningOperationChanged(false);
        }
    }

    @DetectMethodChanges({isAsync: true})
    private async shiftStrikesInternal(shiftDirection: 'up' | 'down', strikeStep: number, notify = true): Promise<void> {

        if (!strikeStep) {
            return;
        }

        if (notify) {
            if (this._applicationSettingsService.adjustmentPricingGrid.linkStrikes) {
                const payload: StrikesChangedMessage = {
                    event: 'shift',
                    side: this.orientation,
                    shiftDirection,
                    strikeStep,
                    underlying: this.underlying
                };

                this._messageBus.publishAsync({
                    topic: StrikesShiftMessageTopic,
                    payload: payload,
                    scopeId: this._scopeId
                });
            }
        }

        if (isVoid(this.positionsData)) {
            return;
        }


        // make copies for closure

        const selectedStrategy = this._selectedStrategy;

        const underlying = this.underlying;

        const tempDefaults = this.lockParameters
            ? this._temporaryState
            : null;

        const selectedPortfolioId = this.selectedPortfolioId;

        const positionsService = this._positionsBeforeStateService;

        async function shiftPositions(pd: PositionsData, ix: number, pairData?: PositionsData)
            : Promise<{ error: string, positions: PositionsData[] }> {

            const shortOption = pd.positions.find(x => x.role === 'ShortOption');

            console.assert(!isVoid(shortOption), 'short option must be available');

            const sign = shiftDirection === 'up' ? 1 : -1;

            let referenceStrike = shortOption.strike + strikeStep * sign;

            if (isNaN(referenceStrike)) {
                referenceStrike = undefined;
            }

            const hasSpread = pd.positions.some(x => x.role.startsWith('Spread'));
            const hasSecondSpread = pd.positions.some(x => x.role.startsWith('SecondSpread'));
            const hasSecondProtectiveOption = pd.positions.some(x => x.role.startsWith('SecondProtective'));

            const includeSpreadTop = hasSpread && ix === 0;
            const includeSpreadBottom = hasSpread && ix === 1;

            const includeSecondSpreadTop = hasSecondSpread && ix === 0;
            const includeSecondSpreadBottom = hasSecondSpread && ix === 1;

            const includeSecondProtectiveOptionTop = hasSecondProtectiveOption && ix === 0;
            const includeSecondProtectiveOptionBottom = hasSecondProtectiveOption && ix === 1;

            const requestParameters: GetPositionsDataRequest = {
                strategyName: pd.strategy,
                isDoubleMode: selectedStrategy === 'Calls & Puts',
                underlying: underlying,
                includeSpreadTop,
                includeSpreadBottom,
                includeSecondSpreadTop,
                includeSecondSpreadBottom,
                includeSecondProtectiveOptionTop,
                includeSecondProtectiveOptionBottom,
                referenceStrike,
                temporaryDefaults: tempDefaults,
                portfolioId: selectedPortfolioId,
                pairStrategy: pairData
            };

            const beforeState = await positionsService.getPositionsState(requestParameters);

            return beforeState;
        }

        const results = [];

        const strategy1 = this.positionsData[0];
        const result1 = await shiftPositions(strategy1, 0);

        let result2: { error: any; positions: any; };
        if (this.positionsData.length > 1) {
            const strategy2 = this.positionsData[1];
            result2 = await shiftPositions(strategy2, 1, result1.positions[0]);
        }

        if (result1.error) {
            this._toastr.error(result1.error);
        } else {
            results.push(result1.positions[0]);
        }

        if (!isVoid(result2)) {
            if (result2.error) {
                this._toastr.error(result2.error);
            } else {
                results.push(result2.positions[0]);
            }
        }

        this.positionsData = results;

        this.updateAtmDistance();
    }

    @DetectMethodChanges({isAsync: true})
    async onStrikesChanged(payload: StrikesChangedMessage): Promise<void> {

        if (!this._applicationSettingsService.adjustmentPricingGrid.linkStrikes) {
            return;
        }

        if (payload.underlying != this.underlying) {
            return;
        }

        if (payload.side == this.orientation) {
            return;
        }

        if (payload.event === 'shift') {

            await this.shiftStrikes(payload.shiftDirection, payload.strikeStep, false);

        } else if (payload.event === 'step') {

            this.strikeStep = payload.strikeStep;
        }
    }

    reset() {
        this.hasChanges = false;
    }

    @DetectMethodChanges()
    onSymbolChanged(x: TradingInstrument) {
        if (this.tradingInstrument == x) {
            return;
        }

        this.tradingInstrument = x;

        this.strikeStep = null;

        // cannot maintain lock because of possible differences
        // in strike step
        // this.lockParameters = false;

        this.hasChanges = true;
    }

    collapseWholeColumn() {
        this._contextDataProvider.toggleCollapsed('positions');
    }

    async applyButtonClicked(): Promise<void> {
        const errors = this.validate();

        if (errors.length > 1) {
            errors.forEach(x => this._toastr.error(x));
            return;
        } else if (errors.length === 1) {
            if (errors[0].indexOf('Incorrect order of legs') >= 0) {
                if (!this._allowApply) {
                    this.isForceApply = true;
                    this._toastr.warning("The configuration of Strike Legs is different from recommended. Please confirm you want to use this configuration?", 'Warning')
                    return;
                }
            }
        }

        this.isForceApply = false;
        await this._contextDataProvider.applyClicked(this._allowApply);
    }

    async forceApplyClicked() {
        this._allowApply = true;

        try {
            await this.applyButtonClicked();
        } finally {
            this._allowApply = false;
            this.isForceApply = false;
        }
    }

    discardForceApplyClicked() {
        this._allowApply = false;
        this.isForceApply = false;
    }

    getBucketRoleClass(item: { role: CashFlowStrategyRole }): string {
        return getBucketRoleClass(item);
    }

    async onStrategyChanged(x: CashFlowStrategy): Promise<void> {
        await this.onStrategyChangedInternal(x);
    }

    private async onStrategyChangedInternal(x: CashFlowStrategy): Promise<void> {

        // same strategy happens when symbol changed occurred
        // and we need to force reload of the positions
        const sameStrategy = x === this._selectedStrategy;

        if (!sameStrategy) {
            this._spreadFirstEnabled = this._spreadSecondEnabled = true;
        }

        if (this.lockParameters) {

            const oldStrategy = this._selectedStrategy;
            const newStrategy = x;

            if ((newStrategy === 'Calls & Puts' || oldStrategy === 'Calls & Puts') &&
                !sameStrategy) {
                // it is not possible to maintain lock when changing between not
                // compatible strategies
                // this.lockParameters = false;
            }
        }

        this._temporaryState = null;

        this._selectedStrategy = x;

        const hasSecondSpreadTop = this.hasSecondSpread('top');
        const hasSecondSpreadBottom = this.hasSecondSpread('bottom');

        const hasSecondProtectiveOptionTop = this.hasSecondProtectiveOption('top');
        const hasSecondProtectiveOptionBottom = this.hasSecondProtectiveOption('bottom');

        const isDoubleMode = x === 'Calls & Puts';

        const parameters: GetPositionsDataRequest = {
            strategyName: x,
            isDoubleMode,
            includeSpreadTop: this.spreadFirstEnabled,
            includeSpreadBottom: this.spreadSecondEnabled,
            includeSecondSpreadTop: hasSecondSpreadTop && sameStrategy,
            includeSecondSpreadBottom: hasSecondSpreadBottom && sameStrategy,
            includeSecondProtectiveOptionTop: hasSecondProtectiveOptionTop && sameStrategy,
            includeSecondProtectiveOptionBottom: hasSecondProtectiveOptionBottom && sameStrategy,
            underlying: this.underlying,
            temporaryDefaults: this.lockParameters ? this._temporaryState : null,
            isInitial: true,
            portfolioId: this.selectedPortfolioId,
        };

        let positionsData: { error: string; positions: PositionsData[]; };

        try {

            positionsData = await this._positionsBeforeStateService
                .getPositionsState(parameters);

        } catch (err) {
            console.error(err);
        }

        if (isVoid(positionsData) || !isVoid(positionsData.error)) {

            if (!sameStrategy) {
                this.clearSavedPositions();

                if (!isVoid(positionsData.error)) {
                    this._toastr.warning(positionsData.error);
                }

                parameters.forceDefaults = true;

                positionsData = await this._positionsBeforeStateService
                    .getPositionsState(parameters);
            }
        }

        this.positionsData = (positionsData.positions || []);

        this.hasChanges = true;

        this.updateAtmDistance();

        this.updatePositionsAfterStateStatus();
    }

    syncPositionsAndSettingsRegardingSecondOptions() {

        let hasSpreadFirst = false;
        let hasSpreadSecond = false;

        let hasSecondSpreadFirst = false;
        let hasSecondSpreadSecond = false;

        let hasSecondProtectiveOptionFirst = false;
        let hasSecondProtectiveOptionSecond = false;

        if (this.positionsData.length > 0) {

            hasSpreadFirst = this.positionsData[0].positions
                .some(x => x.role.startsWith('Spread'));

            hasSecondSpreadFirst = this.positionsData[0].positions
                .some(x => x.role.startsWith('SecondSpread'));

            hasSecondProtectiveOptionFirst = this.positionsData[0].positions
                .some(x => x.role.startsWith('SecondProtective'));

            if (this.positionsData.length > 1) {

                hasSpreadSecond = this.positionsData[1].positions
                    .some(x => x.role.startsWith('Spread'));

                hasSecondSpreadSecond = this.positionsData[1].positions
                    .some(x => x.role.startsWith('SecondSpread'));

                hasSecondProtectiveOptionSecond = this.positionsData[1].positions
                    .some(x => x.role.startsWith('SecondProtective'));
            }
        }

        this._spreadFirstEnabled = hasSpreadFirst;
        this._spreadSecondEnabled = hasSpreadSecond;

        this._contextDataProvider.onPositionsRestored(
            {
                hasSpreadFirst,
                hasSpreadSecond,
                hasSecondSpreadFirst,
                hasSecondSpreadSecond,
                hasSecondProtectiveOptionFirst,
                hasSecondProtectiveOptionSecond
            }
        );

    }

    @DetectMethodChanges({isAsync: true})
    async onPositionExpirationChange(exp: OptionExpirationDescriptor, position: BeforePositionModel) {

        if (isNullOrUndefined(position)) {
            return;
        }

        position.onExpirationChanged(exp);


        const apgSettings = this._applicationSettingsService.adjustmentPricingGrid;

        if (this.positionsData.length > 1) {
            if (apgSettings.matchExpirations) {
                const opposite = this.positionsData.filter(x => x.positions.indexOf(position) < 0);
                if (!isVoid(opposite)) {
                    const oppositePositions = opposite[0].positions;
                    const sameOppositeLeg = oppositePositions.find(x => x.role === position.role);
                    if (sameOppositeLeg) {
                        if (sameOppositeLeg.selectedExpiration != position.selectedExpiration) {
                            sameOppositeLeg.selectedExpiration = position.selectedExpiration;
                        }
                    }
                }
            }
        }

        if (this.savingPositions) {
            this.savePositions();
        }

        this.hasChanges = true;

        await this._contextDataProvider.tryApplyChangesLive();
    }

    @DetectMethodChanges({isAsync: true})
    async onPositionStrikeChanged() {

        if (this.savingPositions) {
            this.savePositions();
        }

        this.hasChanges = true;

        await this._contextDataProvider.tryApplyChangesLive();
    }

    getBeforePositions(): { strategy: CashFlowStrategy, positions: BeforePositionDto[] }[] {
        const positionsErrors = this.validate();

        if (!isVoid(positionsErrors) && !this._allowApply) {
            return undefined;
        }

        const positions = this.positionsData.map(x => {
            return {
                strategy: x.strategy,
                positions: x.positions.map(y => y.asDto())
            };
        });

        return positions;
    }

    validate(): string[] {
        const result = this.positionsData
            .map(x => this.validatePositions(x))
            .filter(x => !isVoid(x));

        if (this.isDoubleMode) {
            const first = this.positionsData[0];
            const second = this.positionsData[1];

            const so1st = first.positions.find(x => x.role === 'ShortOption');
            const so2nd = second.positions.find(x => x.role === 'ShortOption');

            if (so1st.expirationDate !== so2nd.expirationDate) {
                result.push('Incorrect Short Option configuration');
            }

            const sp1st = first.positions.find(x => x.role === 'SpreadLongLeg');
            const sp2nd = second.positions.find(x => x.role === 'SpreadLongLeg');

            if (!isVoid(sp1st) && !isVoid(sp2nd)) {
                if (sp1st.expirationDate !== sp2nd.expirationDate) {
                    result.push('Incorrect Spread configuration');
                }
            }

            const po1st = first.positions.find(x => x.role === 'ProtectiveOption');
            const po2nd = second.positions.find(x => x.role === 'ProtectiveOption');

            if (po1st.expirationDate !== po2nd.expirationDate) {
                result.push('Incorrect Protective Option configuration');
            }

            const secondSpread1st = first.positions.find(x => x.role === 'SecondSpreadLongLeg');
            const secondSpread2nd = second.positions.find(x => x.role === 'SecondSpreadLongLeg');

            if (!isVoid(secondSpread1st) && !isVoid(secondSpread2nd)) {
                if (secondSpread1st.expirationDate !== secondSpread2nd.expirationDate) {
                    result.push('Incorrect 2nd Spread Configuration');
                }
            }

            const secondProtectiveOption1st = first.positions.find(x => x.role === 'SecondProtectiveOption');
            const secondProtectiveOption2nd = second.positions.find(x => x.role === 'SecondProtectiveOption');

            if (!isVoid(secondProtectiveOption1st) && !isVoid(secondProtectiveOption2nd)) {
                if (secondProtectiveOption1st.expirationDate !== secondProtectiveOption2nd.expirationDate) {
                    result.push('Incorrect 2nd Protective Option Configuration');
                }
            }
        }

        return result;
    }

    private validatePositions(data: PositionsData): string {

        const shortOption = data.positions.find(x => x.role === 'ShortOption');
        const spreadLongLeg = data.positions.find(x => x.role === 'SpreadLongLeg');
        const spreadShortLeg = data.positions.find(x => x.role === 'SpreadShortLeg');
        const protectiveOption = data.positions.find(x => x.role === 'ProtectiveOption');
        const secondSpreadLongLeg = data.positions.find(x => x.role === 'SecondSpreadLongLeg');
        const secondSpreadShortLeg = data.positions.find(x => x.role === 'SecondSpreadShortLeg');
        const secondProtectiveOption = data.positions.find(x => x.role === 'SecondProtectiveOption');

        if (
            isVoid(shortOption) ||
            isVoid(protectiveOption)
        ) {
            return 'Incorrect initial positions configuration';
        }

        if ((spreadLongLeg && !spreadShortLeg)
            || (spreadShortLeg && !spreadLongLeg)) {
            return 'Incorrect initial positions configuration';
        }

        if ((secondSpreadLongLeg && !secondSpreadShortLeg)
            || (!secondSpreadLongLeg && secondSpreadShortLeg)) {
            return 'Incorrect initial positions configuration';
        }

        if (isVoid(shortOption) || !shortOption.validate()) {
            return 'Incorrect short option configuration';
        }

        if (!isVoid(spreadLongLeg) && !spreadLongLeg.validate()) {
            return 'Incorrect spread long leg configuration';
        }

        if (!isVoid(spreadShortLeg) && !spreadShortLeg.validate()) {
            return 'Incorrect spread short leg configuration';
        }

        if (isVoid(protectiveOption) || !protectiveOption.validate()) {
            return 'Incorrect protective option configuration';
        }

        if (secondSpreadLongLeg) {
            if (!secondSpreadLongLeg.validate()) {
                return 'Incorrect second spread long leg configuration';
            }
        }

        if (secondSpreadShortLeg) {
            if (!secondSpreadShortLeg.validate()) {
                return 'Incorrect second spread short leg configuration';
            }
        }

        if (secondProtectiveOption) {
            if (!secondProtectiveOption.validate()) {
                return 'Incorrect second protective option leg configuration';
            }
        }

        const isReversed = isReversedCashFlowOrder(data.strategy);

        const spreadExists = !isVoid(spreadLongLeg)
            && !isVoid(spreadShortLeg);

        const secondSpreadExists = !isVoid(secondSpreadLongLeg)
            && !isVoid(secondSpreadShortLeg);


        if (!spreadExists && !secondSpreadExists) {
            return 'One of the spreads must exist';
        }

        const secondProtectiveOptionExists = !isVoid(secondProtectiveOption);

        const secondSpreadLongLegStrike = secondSpreadLongLeg
            ? secondSpreadLongLeg.strike
            : NaN;

        const secondSpreadShortLegStrike = secondSpreadShortLeg
            ? secondSpreadShortLeg.strike
            : NaN;

        const secondProtectiveOptionStrike = secondProtectiveOptionExists
            ? secondProtectiveOption.strike
            : NaN;

        let shortOptionVsSpreadLongLeg = spreadExists ? numberCompare(
            shortOption.strike,
            spreadLongLeg.strike
        ) : 0;

        let spreadLongLegVsSpreadShortLeg = spreadExists ? numberCompare(
            spreadLongLeg.strike,
            spreadShortLeg.strike
        ) : 1;

        let spreadShortLegVsSecondSpreadLongLeg = spreadExists ? numberCompare(
            spreadShortLeg.strike,
            secondSpreadLongLegStrike
        ) : 0;

        let secondSpreadLongLegVsSecondSpreadShortLeg = numberCompare(
            secondSpreadLongLegStrike,
            secondSpreadShortLegStrike
        );

        let secondSpreadShortLegVsProtectiveOption = numberCompare(
            secondSpreadShortLegStrike,
            protectiveOption.strike
        );

        let spreadShortLegVsProtectiveOption = spreadExists ? numberCompare(
            spreadShortLeg.strike,
            protectiveOption.strike
        ) : 0;

        let protectiveOptionVsSecondProtectiveOption = numberCompare(
            protectiveOption.strike,
            secondProtectiveOptionStrike,
        );

        if (isReversed) {
            shortOptionVsSpreadLongLeg *= -1;
            spreadLongLegVsSpreadShortLeg *= -1;
            spreadShortLegVsSecondSpreadLongLeg *= -1;
            secondSpreadLongLegVsSecondSpreadShortLeg *= -1;
            secondSpreadShortLegVsProtectiveOption *= -1;
            spreadShortLegVsProtectiveOption *= -1;
            protectiveOptionVsSecondProtectiveOption *= -1;
        }

        if (
            shortOptionVsSpreadLongLeg == -1
            || spreadExists && spreadLongLegVsSpreadShortLeg <= 0
            || secondSpreadExists && spreadShortLegVsSecondSpreadLongLeg < 0
            || secondSpreadExists && secondSpreadLongLegVsSecondSpreadShortLeg <= 0
            || secondSpreadExists && secondSpreadShortLegVsProtectiveOption < 0
            || spreadExists && spreadShortLegVsProtectiveOption < 0
            || secondProtectiveOptionExists && protectiveOptionVsSecondProtectiveOption <= 0
        ) {
            return 'Incorrect order of legs';
        }

        const nettingPositions = Enumerable
            .from(data.positions)
            .groupBy(x => `${x.type}_${x.expirationDate}_${x.strike}`)
            .select(x => x.sum(y => y.qty))
            .count(x => x === 0);

        if (nettingPositions > 0) {
            return 'Please check positions. Some legs are offsetting each other';
        }

        return null;
    }

    async onSecondSpreadChanged(include: boolean, strategyName: CashFlowStrategy): Promise<void> {

        const referenceStrike = this.getReferenceStrike();

        const isDoubleMode = this._selectedStrategy === 'Calls & Puts';

        //#region Include 2nd PO?

        let includeSecondProtectiveOptionTop: boolean =
            this.positionsData[0].positions.some(x => x.role.startsWith('SecondProtective'));

        let includeSecondProtectiveOptionBottom = false;
        if (this.positionsData.length > 1) {
            includeSecondProtectiveOptionBottom =
                this.positionsData[1].positions.some(x => x.role.startsWith('SecondProtective'));
        }

        //#endregion

        let includeSecondSpreadTop: boolean;
        let includeSecondSpreadBottom: boolean;

        if (isDoubleMode) {
            includeSecondSpreadTop = include && strategyName === 'Calls';
            includeSecondSpreadBottom = include && strategyName === 'Puts'
        } else {
            includeSecondSpreadTop = include;
        }

        const parameters: GetPositionsDataRequest = {
            strategyName,
            isDoubleMode,
            includeSpreadTop: this.spreadFirstEnabled,
            includeSpreadBottom: this.spreadSecondEnabled,
            includeSecondSpreadTop,
            includeSecondSpreadBottom,
            includeSecondProtectiveOptionTop,
            includeSecondProtectiveOptionBottom,
            underlying: this.underlying,
            referenceStrike,
            temporaryDefaults: this._temporaryState,
            portfolioId: this.selectedPortfolioId
        };

        const beforeState = await this._positionsBeforeStateService
            .getPositionsState(parameters);

        const strategyPositions = beforeState.positions.find(x => x.strategy === strategyName);

        console.assert(!isVoid(strategyPositions), 'position data required');

        const ix = this.positionsData.findIndex(x => x.strategy === strategyName);

        console.assert(ix != -1, 'position data must exist');

        // copy needs for GUI refresh
        const copyData = this.positionsData;

        copyData[ix] = strategyPositions;

        this.positionsData = copyData;

        this.hasChanges = true;
    }

    async onSpreadChanged(strategyName: CashFlowStrategy): Promise<void> {

        const referenceStrike = this.getReferenceStrike();

        const isDoubleMode = this._selectedStrategy === 'Calls & Puts';

        //#region Include 2nd PO?

        let includeSecondProtectiveOptionTop: boolean =
            this.positionsData[0].positions.some(x => x.role.startsWith('SecondProtective'));

        let includeSecondProtectiveOptionBottom = false;
        if (this.positionsData.length > 1) {
            includeSecondProtectiveOptionBottom =
                this.positionsData[1].positions.some(x => x.role.startsWith('SecondProtective'));
        }

        //#endregion

        //#region Include 2nd Spread?

        let includeSecondSpreadTop: boolean =
            this.positionsData[0].positions.some(x => x.role.startsWith('SecondSpread'));

        let includeSecondSpreadBottom = false;
        if (this.positionsData.length > 1) {
            includeSecondSpreadBottom =
                this.positionsData[1].positions.some(x => x.role.startsWith('SecondSpread'));
        }

        //#endregion

        const parameters: GetPositionsDataRequest = {
            strategyName,
            isDoubleMode,
            includeSpreadTop: this.spreadFirstEnabled,
            includeSpreadBottom: this.spreadSecondEnabled,
            includeSecondSpreadTop,
            includeSecondSpreadBottom,
            includeSecondProtectiveOptionTop,
            includeSecondProtectiveOptionBottom,
            underlying: this.underlying,
            referenceStrike,
            temporaryDefaults: this._temporaryState,
            portfolioId: this.selectedPortfolioId
        };

        const beforeState = await this._positionsBeforeStateService
            .getPositionsState(parameters);

        const strategyPositions = beforeState.positions.find(x => x.strategy === strategyName);

        console.assert(!isVoid(strategyPositions), 'position data required');

        const ix = this.positionsData.findIndex(x => x.strategy === strategyName);

        console.assert(ix != -1, 'position data must exist');

        // copy needs for GUI refresh
        const copyData = this.positionsData;

        copyData[ix] = strategyPositions;

        this.positionsData = copyData;

        this.hasChanges = true;
    }

    async onSecondProtectiveOptionChanged(include: boolean, strategyName: CashFlowStrategy): Promise<void> {

        if (isVoid(this.positionsData)) {
            return;
        }

        const referenceStrike = this.getReferenceStrike();

        const isDoubleMode = this._selectedStrategy === 'Calls & Puts';

        //#region Include 2nd Spread?

        let includeSecondSpreadTop: boolean
            = this.positionsData[0].positions.some(x => x.role.startsWith('SecondSpread'));

        let includeSecondSpreadBottom = false;

        if (this.positionsData.length > 1) {
            includeSecondSpreadBottom =
                this.positionsData[1].positions.some(x => x.role.startsWith('SecondSpread'));
        }

        //#endregion

        let includeSecondProtectiveOptionTop: boolean;
        let includeSecondProtectiveOptionBottom: boolean;

        if (isDoubleMode) {
            includeSecondProtectiveOptionTop = include && strategyName === 'Calls';
            includeSecondProtectiveOptionBottom = include && strategyName === 'Puts'
        } else {
            includeSecondProtectiveOptionTop = include;
        }

        const parameters: GetPositionsDataRequest = {
            strategyName,
            isDoubleMode,
            includeSpreadTop: this.spreadFirstEnabled,
            includeSpreadBottom: this.spreadSecondEnabled,
            includeSecondSpreadTop,
            includeSecondSpreadBottom,
            includeSecondProtectiveOptionTop,
            includeSecondProtectiveOptionBottom,
            underlying: this.underlying,
            referenceStrike,
            temporaryDefaults: this._temporaryState,
            portfolioId: this.selectedPortfolioId
        };

        if (isDoubleMode) {
            const pData = strategyName === 'Calls' ? this.positionsData[1] : this.positionsData[0];
            parameters.pairStrategy = pData;
        }

        const beforeState = await this._positionsBeforeStateService
            .getPositionsState(parameters);

        const strategyPositions = beforeState.positions.find(x => x.strategy === strategyName);

        console.assert(!isVoid(strategyPositions), 'position data required');

        const ix = this.positionsData.findIndex(x => x.strategy === strategyName);

        console.assert(ix != -1, 'position data must exist');

        // copy needs for GUI refresh
        const copyData = this.positionsData;

        copyData[ix] = strategyPositions;

        this.positionsData = copyData;

        this.hasChanges = true;
    }

    @DetectMethodChanges()
    updateAtmDistance() {

        if (isVoid(this.positionsData)) {
            this.atmDistance = undefined;
            return;
        }

        const atm = this._atmStrikeService.getCurrentAtm(this.underlying);

        if (isVoid(atm)) {
            return;
        }

        const data = this.positionsData[0];

        if (isVoid(data) || isVoid(data.positions)) {
            return;
        }

        const shortOption = data.positions.find(x => x.role === 'ShortOption');

        if (isVoid(shortOption)) {
            return;
        }

        const distance = atm - shortOption.strike;

        const adjustedDistance = this._atmStrikeService.calculateCurrentAtm(this.underlying, distance);

        this.atmDistance = adjustedDistance;
    }

    //#region Before State Settings API

    async saveBeforeStateAsDefault(): Promise<void> {

        if (isVoid(this.positionsData)) {

            this._toastr.warning('Nothing to save');

            return;

        }

        const validationResult = this.validate();

        if (!isVoid(validationResult)) {
            validationResult.forEach(x => this._toastr.error(x));
            return;
        }


        try {

            const currentState = this.captureTemporaryState();

            const pfId = this._selectedPortfolio ? this._selectedPortfolio.id : null;
            await this._positionsBeforeStateService.saveDefaultsProvider(
                pfId,
                this.underlying,
                currentState
            );

            this._toastr.success('Current state has been saved as default', 'Positions');

        } catch {
            this._toastr.error('"Save as Defaults" operation completed with errors', 'Positions');
        }

    }

    private captureTemporaryState(): PositionsDefaultsProvider {

        const oldState: any = this._temporaryState || {};

        if (isVoid(this.positionsData)) {
            this._temporaryState = null;
            return;
        }

        const validationResult = this.validate();

        if (!isVoid(validationResult)) {

            console.warn('temporary state captured with some errors');

            validationResult.forEach(str => console.warn(`\t${str}`));

        }

        const defaults: PositionsDefaultsProvider = {
            first: undefined,
            second: undefined
        };

        this.positionsData.forEach((beforeState, ix) => {

            if (isVoid(beforeState) || isVoid(beforeState.positions)) {
                this._toastr.warning('Nothing to save');
                return;
            }

            const oldStateValues = (ix === 0 ? oldState.first : oldState.second) || {};

            const poses = beforeState.positions;

            const shortOption = poses.find(x => x.role === 'ShortOption');
            const spreadLong = poses.find(x => x.role === 'SpreadLongLeg');
            const spreadShort = poses.find(x => x.role === 'SpreadShortLeg');
            const secondSpreadLong = poses.find(x => x.role === 'SecondSpreadLongLeg');
            const secondSpreadShort = poses.find(x => x.role === 'SecondSpreadShortLeg');
            const protectiveOption = poses.find(x => x.role === 'ProtectiveOption');
            const secondProtectiveOption = poses.find(x => x.role === 'SecondProtectiveOption');

            let atmOffset = this.atmDistance || oldStateValues.atmOffset || 0;

            let spreadOffset = (!isVoid(spreadLong) && !isVoid(shortOption))
                ? Math.abs(shortOption.strike - spreadLong.strike)
                : oldStateValues.spreadOffset;

            let spreadWidth = (!isVoid(spreadLong) && !isVoid(spreadShort))
                ? Math.abs(spreadLong.strike - spreadShort.strike)
                : oldState.first.spreadWidth;

            const secondSpreadExists = !isVoid(secondSpreadLong) && !isVoid(secondSpreadShort);

            const secondProtectiveOptionExists = !isVoid(secondProtectiveOption);

            let secondSpreadWidth: number;
            let secondSpreadOffset: number;

            if (secondSpreadExists) {
                secondSpreadWidth = Math.abs(secondSpreadLong.strike - secondSpreadShort.strike) || oldStateValues.secondSpreadWidth;
                secondSpreadOffset = Math.abs((spreadShort || shortOption).strike - secondSpreadLong.strike) || oldStateValues.secondSpreadOffset;
            }

            const relativeLegForProtectiveOption = secondSpreadExists
                ? secondSpreadShort
                : spreadShort;

            let protectiveOptionOffset =
                Math.abs(protectiveOption.strike - relativeLegForProtectiveOption.strike) ||
                oldStateValues.protectiveOptionOffset;

            const shortOptionDaysToExpiration = shortOption.selectedExpiration.daysToExpiration;

            const spreadDaysToExpiration = !isVoid(spreadLong)
                ? spreadLong.selectedExpiration.daysToExpiration
                : undefined;

            let secondSpreadDaysToExpiration: number;

            if (secondSpreadExists) {
                secondSpreadDaysToExpiration = secondSpreadLong.selectedExpiration.daysToExpiration;
            }

            const protectiveOptionDaysToExpiration = !isVoid(protectiveOption)
                ? protectiveOption.selectedExpiration.daysToExpiration
                : undefined;

            let secondProtectiveOptionOffset: number;
            let secondProtectiveOptionDaysToExpiration: number;

            if (secondProtectiveOptionExists && !isVoid(protectiveOption)) {

                secondProtectiveOptionOffset = Math.abs(protectiveOption.strike - secondProtectiveOption.strike)
                    || oldStateValues.secondProtectiveOptionOffset;

                secondProtectiveOptionDaysToExpiration = secondProtectiveOption.selectedExpiration.daysToExpiration;
            }

            function getValue(value: number) {
                return isValidNumber(value) ? value : undefined;
            }

            const settings: PositionsDefaultSettings = {
                atmOffset: getValue(atmOffset),
                shortOptionDaysToExpiration: getValue(shortOptionDaysToExpiration),

                spreadOffset: getValue(spreadOffset),
                spreadWidth: getValue(spreadWidth),
                spreadDaysToExpiration: getValue(spreadDaysToExpiration),

                includeSecondSpread: secondSpreadExists || false,
                secondSpreadOffset: getValue(secondSpreadOffset),
                secondSpreadWidth: getValue(secondSpreadWidth),
                secondSpreadDaysToExpiration: getValue(secondSpreadDaysToExpiration),

                protectiveOptionOffset: getValue(protectiveOptionOffset),
                protectiveOptionDaysToExpiration: getValue(protectiveOptionDaysToExpiration),

                includeSecondProtectiveOption: secondProtectiveOptionExists || false,
                secondProtectiveOptionOffset: getValue(secondProtectiveOptionOffset),
                secondProtectiveOptionDaysToExpiration: getValue(secondProtectiveOptionDaysToExpiration),

                orderQty: undefined,

                expirationSmartMode: new ExpirationSmartModeSettings()
            };

            if (ix === 0) {
                defaults.first = settings;
            } else {
                defaults.second = settings;
            }

        });

        return defaults;
    }

    showBeforeStateDefaultsPopup() {
        const pfId = this._selectedPortfolio ? this._selectedPortfolio.id : null;
        this.defaultSettingsPopup.show(
            pfId,
            this.underlying,
            this._selectedStrategy
        );
    }

    //#endregion

    private getReferenceStrike(): number | undefined {

        if (isVoid(this.positionsData)) {
            return undefined;
        }

        const anyData = this.positionsData[0];

        const so = anyData.positions.find(x => x.role === 'ShortOption');

        console.assert(!isVoid(so), 'short option required');

        return so.strike;
    }

    private hasSecondSpread(strategy: 'top' | 'bottom'): boolean {

        if (isVoid(this.positionsData)) {
            return false;
        }

        let result = false;

        if (strategy === 'top') {

            result = this.positionsData[0]
                .positions.some(x => x.role.startsWith('SecondSpread'));

        } else if (strategy === 'bottom') {

            if (this.positionsData.length > 1) {
                result = this.positionsData[1].positions
                    .some(x => x.role.startsWith('SecondSpread'));
            }

        }

        return result;

    }

    private hasSecondProtectiveOption(strategy: 'top' | 'bottom'): boolean {

        if (isVoid(this.positionsData)) {
            return false;
        }

        let result = false;

        if (strategy === 'top') {

            result = this.positionsData[0]
                .positions.some(x => x.role.startsWith('SecondProtective'));

        } else if (strategy === 'bottom') {

            if (this.positionsData.length > 1) {
                result = this.positionsData[1].positions
                    .some(x => x.role.startsWith('SecondProtective'));
            }

        }

        return result;

    }

    private savePositions() {

        this._savedPositionsService.save(
            this._selectedPortfolio ? this._selectedPortfolio.id : undefined,
            this.underlying,
            this._selectedStrategy,
            this.positionsData
        );

        this.positionsChanged$.emit();

    }

    private clearSavedPositions() {

        this._savedPositionsService.clear(
            this._selectedPortfolio ? this._selectedPortfolio.id : undefined,
            this.underlying,
            this._selectedStrategy
        );
    }

    onPortfolioSelected(portfolio: ApgPortfolio) {

        this._selectedPortfolio = portfolio;
        this.positionsData = [];
        this.tradingInstrument = undefined;
        this._selectedStrategy = undefined;

        this.updateAtmDistance();

    }

    @DetectMethodChanges()
    updatePositionsAfterStateStatus(): void {
        const state = this._positionsAfterStateService.getState(
            this.selectedPortfolioId,
            this.underlying,
            this._selectedStrategy
        );

        if (isVoid(state)) {
            this.hasAfterStateFirst = this.hasAfterStateSecond = false;
            return;
        }

        this.hasAfterStateFirst = !isVoid(state.first);

        this.hasAfterStateSecond = !isVoid(state.second);

        this.afterStateTooltipFirst = isVoid(state.first)
            ? null
            : `${state.first.adjustmentDescriptor}`;

        this.afterStateTooltipSecond = isVoid(state.second)
            ? null
            : `${state.second.adjustmentDescriptor}`;
    }

    @DetectMethodChanges({isAsync: true})
    async applyPositionsAfterState(slot: 'first' | 'second'): Promise<void> {

        if (slot === 'first') {
            if (!this._hasAfterStateFirst) {
                return;
            }
        }

        if (slot === 'second') {
            if (!this._hasAfterStateSecond) {
                return;
            }
        }


        const state = this._positionsAfterStateService.getState(
            this.selectedPortfolioId,
            this.underlying,
            this._selectedStrategy
        );

        if (isVoid(state)) {
            this._hasAfterStateFirst = this._hasAfterStateSecond = false;
            this._toastr.error('Saved Adjustment State not Found')
            return;
        }


        try {
            await this.afterStatePreview.show(
                slot === 'first'
                    ? state.first
                    : state.second
            );
        } catch {
            return;
        }

        if (slot === 'first') {

            if (isVoid(state.first)) {
                this._toastr.error('Saved Adjustment State not Found for selected strategy');
                return;
            }

            await this.applyPositionsAfterStateInternal(state.first.positions, slot);

            state.first = null;

        } else if (slot === 'second') {

            if (isVoid(state.second)) {
                this._toastr.error('Saved Adjustment State not Found for selected strategy');
                return;
            }

            await this.applyPositionsAfterStateInternal(state.second.positions, slot);

            state.second = null;

        }

        if (isVoid(state.first) && isVoid(state.second)) {

            this._positionsAfterStateService.removeState(
                state.portfolioId,
                state.underlying,
                state.strategy
            );

            const soTickers = this.positionsData
                .flatMap(x => x.positions.find(y => y.role === 'ShortOption'))
                .map(x => x.expirationDate);

            if (soTickers.length > 0) {
                if (soTickers.length === 1 || (soTickers[0] === soTickers[1])) {
                    await this.updateTrackingAfterStateApplied();
                }
            }

        } else {

            this._positionsAfterStateService.updateState(state);
        }

        this.updateAtmDistance();

        this.hasChanges = true;
    }

    private async applyPositionsAfterStateInternal(
        positions: SolutionPositionDto[],
        slot: 'first' | 'second'
    ): Promise<void> {

        if (slot === 'second') {
            if (this.positionsData.length < 2) {
                this._toastr.error('Cannot apply state for "Put" strategy. Selected strategy is not "Calls & Puts"');
                return;
            }
        }

        const models = await this._positionsBeforeStateService
            .makeFromAfterState(positions, this.underlying);

        const pData: PositionsData = {
            isSavedState: true,
            positions: models,
            strategy: models[0].strategy
        };

        if (slot === 'first') {

            this.positionsData[0] = pData;

        } else if (slot === 'second') {

            this.positionsData[1] = pData;

        }

        if (this.savingPositions) {
            this.savePositions();
        }
    }

    @DetectMethodChanges({isAsync: true})
    async fullReset(): Promise<void> {

        const underlying = this.underlying || this._applicationSettingsService.adjustmentPricingGrid.defaultUnderlying;
        const strategy = this._selectedStrategy || this._applicationSettingsService.adjustmentPricingGrid.defaultStrategy;

        const request: GetPositionsDataRequest = {
            strategyName: strategy,
            isDoubleMode: this.isDoubleMode,
            includeSpreadTop: true,
            includeSpreadBottom: true,
            underlying,
            temporaryDefaults: null,
            portfolioId: this.selectedPortfolioId,
            isInitial: true,
            forceDefaults: true,
        };

        try {

            this._contextDataProvider.onLongRunningOperationChanged(true);

            await delay(250);

            const state = await this._positionsBeforeStateService.getPositionsState(
                request
            );

            if (!isVoid(state.error)) {
                this._toastr.error(
                    'Cannot rebuild default positions. ' +
                    'If this prevents you from continuing your work, please consider log-out and log-in back with ' +
                    '"Reset Layout" checkbox checked');
                return;
            }

            this.positionsData = state.positions;

            this.updateAtmDistance();

            this.hasChanges = true;

            this.syncPositionsAndSettingsRegardingSecondOptions();

        } finally {

            this._contextDataProvider.onLongRunningOperationChanged(false);

            if (this.savingPositions) {
                this.savePositions();
            }

        }
    }

    async getPositionsBasedOnCustomStrike(strike: number) {

        const underlying = this.underlying || this._applicationSettingsService.adjustmentPricingGrid.defaultUnderlying;
        const strategy = this._selectedStrategy || this._applicationSettingsService.adjustmentPricingGrid.defaultStrategy;

        const request: GetPositionsDataRequest = {
            strategyName: strategy,
            isDoubleMode: this.isDoubleMode,
            includeSpreadTop: true,
            includeSpreadBottom: true,
            underlying,
            temporaryDefaults: null,
            portfolioId: this.selectedPortfolioId,
            isInitial: true,
            forceDefaults: true,
            referenceStrike: strike
        };

        const state = await this._positionsBeforeStateService.getPositionsState(
            request
        );

        return state.positions;
    }


    @DetectMethodChanges()
    clearSavedAdjustment() {
        if (!this.hasAfterState) {
            return;
        }

        this._positionsAfterStateService.removeState(
            this.selectedPortfolioId,
            this.underlying,
            this._selectedStrategy
        );
    }

    @DetectMethodChanges({isAsync: true})
    async onTrackMenuItemClick(item: ItemClickEvent) {

        if (item.itemData.id === 'settings') {

            const cfg: SweetPricePopupConfig = {
                underlying: this.underlying,
                positions: this.positionsData
            } as any;

            this._contextDataProvider.onSweetPriceSettingsRequest(cfg);

            await this.trackingDialog.show(cfg);

        } else if (item.itemData.id === 'start') {

            await this._contextDataProvider.onStartTrackingRequest();

        } else if (item.itemData.id === 'stop') {

            await this._contextDataProvider.onStopTrackingRequest();

        } else if (item.itemData.id === 'sync') {

            await this._contextDataProvider.onSweetPriceSyncRequest();

        } else {

            this._toastr.error('unknown');

        }
    }

    @DetectMethodChanges({isAsync: true})
    async syncBeforePositions(snapshotSettings: AdjustmentPricingSettingsDto[], chainsService: OptionsChainService) {

        const underlying = snapshotSettings[0].underlying;
        const strategy: CashFlowStrategy = snapshotSettings.length == 2
            ? 'Calls & Puts'
            : snapshotSettings[0].strategy;

        const chain = await chainsService.getChain(underlying);

        const models = snapshotSettings.map(x => x.beforeState.map(y => BeforePositionModel.fromDto(y, chain)));

        models.forEach(m => {
            const spreadLong = m.find(x => x.role === 'SpreadLongLeg');
            const spreadShort = m.find(x => x.role === 'SpreadShortLeg');

            if (spreadLong && spreadShort) {
                spreadLong.addDependentPosition(spreadShort);
            }

            const spreadLong2 = m.find(x => x.role === 'SecondSpreadLongLeg');
            const spreadShort2 = m.find(x => x.role === 'SecondSpreadShortLeg');
            if (spreadLong2 && spreadShort2) {
                spreadLong2.addDependentPosition(spreadShort2);
            }
        });

        const positionsData = models.map(x => {
            return {
                isSavedState: true,
                positions: x,
                strategy: x[0].strategy
            } as PositionsData
        });

        const ti = this._tiService.getInstrumentByTicker(underlying);

        // this will let restore this positions on strategy change
        this._savedPositionsService.save(this.selectedPortfolioId, underlying, strategy, positionsData);

        this.onSymbolChanged(ti);

        await this.onStrategyChanged(strategy);
    }

    setEventHandler(arg: ContextDataProvider) {
        this._contextDataProvider = arg;
    }

    getTrackingButtonCssClass(): string[] {

        const result = ['btn', 'sweetprice-tracking'];

        const isTracking = this._contextDataProvider.sweetPriceIsTracking();

        if (isTracking) {

            result.push('tracking-on');

            const isSync = this._contextDataProvider.sweetPriceIsSync();

            if (!isSync) {
                result.push('out-of-sync');
            }
        }

        return result;
    }

    async updateTrackingAfterStateApplied(): Promise<void> {
        if (!this.isTracking) {
            return;
        }

        try {

            await this._contextDataProvider.onStartTrackingRequest();

        } catch (e) {
            console.error(e, 'updateTrackingAfterStateApplied() operation failed');
        }

        this._toastr.info('Sweet Price Tracking data will be automatically updated, ' +
            'because you have applied new state of positions');
    }

    async onSweetPriceSettingsUpdated() {

        if (!this.isTracking) {
            return;
        }

        await this._contextDataProvider.onSweetPriceSettingsUpdated();
    }

    onResolvingDynamicOffsets() {
        this._contextDataProvider.onLongRunningOperationChanged(true);
    }

    async onDynamicOffsetsResolved(value: number) {
        try {

            await this.onDynamicOffsetsResolvedInternal(value);

        } catch (e) {

            console.error(e);

        } finally {

            this._contextDataProvider.onLongRunningOperationChanged(false);

            if (this.savingPositions) {
                this.savePositions();
            }

        }
    }

    private async onDynamicOffsetsResolvedInternal(value: number) {

        const underlying = this.underlying || this._applicationSettingsService.adjustmentPricingGrid.defaultUnderlying;

        const strategy = this._selectedStrategy || this._applicationSettingsService.adjustmentPricingGrid.defaultStrategy;

        const tempState = this.captureTemporaryState()

        tempState.first.spreadOffset = value;
        if (tempState.second) {
            tempState.second.spreadOffset = value;
        }

        const so = this.positionsData[0].positions.find(x => x.role === 'ShortOption');
        const soTicker = so.getTicker();
        const tickerObject = parseOptionTicker(soTicker);

        const firstPositions = this.positionsData[0];
        const secondPositions = this.positionsData[1];

        const includeSpreadTop = firstPositions.positions.some(x => x.role.startsWith('Spread'));
        const includeSpreadBottom = isVoid(secondPositions) ? false : secondPositions.positions.some(x => x.role.startsWith('Spread'));

        const includeSecondSpreadTop = firstPositions.positions.some(x => x.role.startsWith('SecondSpread'));
        const includeSecondSpreadBottom = isVoid(secondPositions) ? false : secondPositions.positions.some(x => x.role.startsWith('SecondSpread'));

        const includeSecondProtectiveOptionTop = firstPositions.positions.some(x => x.role.startsWith('SecondProtective'));
        const includeSecondProtectiveOptionBottom = isVoid(secondPositions) ? false : secondPositions.positions.some(x => x.role.startsWith('SecondProtective'));

        const request: GetPositionsDataRequest = {
            strategyName: strategy,
            isDoubleMode: this.isDoubleMode,
            underlying,
            includeSpreadTop,
            includeSpreadBottom,
            includeSecondSpreadTop,
            includeSecondSpreadBottom,
            includeSecondProtectiveOptionTop,
            includeSecondProtectiveOptionBottom,
            temporaryDefaults: tempState,
            portfolioId: this.selectedPortfolioId,
            referenceStrike: tickerObject.strike
        };

        const state = await this._positionsBeforeStateService.getPositionsState(
            request
        );

        if (!isVoid(state.error)) {
            this._toastr.error('Cannot rebuild default positions. ' +
                'If this prevents you from continuing your work, please consider log-out and log-in back with ' +
                '"Reset Layout" checkbox checked');
            return;
        }

        this.positionsData = state.positions;

        this.updateAtmDistance();

        this.hasChanges = true;

        this.syncPositionsAndSettingsRegardingSecondOptions();
    }

    getPositions(): BeforePositionModel[][] {
        const shorts = this._positionsData.map(x => x.positions);
        return shorts;
    }

    async onCtxMenuItemClick($event: CtxMenuItems | string): Promise<void> {
        if ($event === 'Copy') {

            const positions = this.positionsData
                .map(x => x.positions.map(y => y.asDto()));

            let json = JSON.stringify(positions);

            json = 'apg.copy/paste.positions' + json;

            navigator.clipboard
                .writeText(json)
                .then(_ => this._toastr.success('Copied!'))
                .catch(e => this._toastr.error(e.menuItems));

        } else if ($event === 'Copy With Settings') {

            this._contextDataProvider.copyPositionsAndSettings();

        } else if ($event === 'Paste') {

            this._contextDataProvider.onLongRunningOperationChanged(true);

            navigator.clipboard
                .readText()
                .then(text => {

                    if (isVoid(text)) {
                        return;
                    }

                    if (!text.startsWith('apg.copy/paste.positions')) {
                        return;
                    }

                    const json = text.split('apg.copy/paste.positions')[1];

                    const dtos = JSON.parse(json) as BeforePositionDto[][];

                    if (isVoid(dtos)) {
                        console.error('bad dtos');
                        return;
                    }

                    const positionsFirst = dtos[0];

                    if (isVoid(positionsFirst)) {
                        return;
                    }

                    const ticker = positionsFirst[0].ticker;

                    const optionTicker = parseOptionTicker(ticker);

                    if (isVoid(optionTicker)) {
                        console.error('bad option ticker');
                        return;
                    }

                    const ul = optionTicker.underlying;

                    if (ul !== this.underlying && !isVoid(this.underlying)) {
                        this._toastr.error('Positions you are trying to paste have ' +
                            'different underlying than currently selected');
                        return;
                    }

                    const pasteStrategy: CashFlowStrategy = dtos.length === 2
                        ? 'Calls & Puts'
                        : positionsFirst[0].strategy;

                    if (this._selectedStrategy !== pasteStrategy && !isVoid((this._selectedStrategy))) {
                        this._toastr.error('Positions you are trying to paste have ' +
                            'different strategy than currently selected');
                        return;
                    }

                    this._positionsBeforeStateService
                        .getOptionChain(ul)
                        .then(chain => {

                            if (isVoid(chain)) {
                                return;
                            }

                            const models = dtos.map(positions => {
                                const model = this._positionsBeforeStateService
                                    .makeBeforePositionModelFromDto(positions, chain);
                                return model;
                            });

                            const positionsData = models.map(m => {
                                return {
                                    positions: m,
                                    strategy: m[0].strategy,
                                    isSavedState: true
                                } as PositionsData;
                            });

                            if (!isVoid(this.selectedPortfolioId)) {

                                this.positionsData = positionsData;
                                this._contextDataProvider.onTemplateApplied();

                            } else {
                                this._contextDataProvider.pastePositions(positionsData);
                            }

                        });

                })
                .finally(() => {
                    this._contextDataProvider.onLongRunningOperationChanged(false);
                });

        } else if ($event === 'Paste Positions & Settings') {

            await this._contextDataProvider.pastePositionsAndSettings();

        }
    }

    toggleCtxMenu() {

        this.ctxMenuItems.length = 0;

        const tpl = this._contextDataProvider.getSelectedTemplate();

        if (!isVoid(tpl)) {
            this.ctxMenuItems.push({text: 'Copy'});
            this.ctxMenuItems.push({text: 'Copy With Settings'});
        }

        // this complexity related to the fact that we have to maintain
        // array reference the same to prevent redrawing the ctx menu
        navigator.clipboard.readText()
            .then(text => {

                const hasPositionsInClipboard = text.startsWith('apg.copy/paste.positions');

                if (hasPositionsInClipboard) {
                    this.ctxMenuItems.push({text: 'Paste'});
                } else {
                    const hasComboInClipboard = text.startsWith('apg.copy/paste.combination');
                    if (hasComboInClipboard) {
                        this.ctxMenuItems.push({text: 'Paste Positions & Settings'});
                    }
                }

                this.ctxMenu.toggle();

            })
            .catch(e => console.error(e));
    }

    @DetectMethodChanges({isAsync: true})
    private async onOrderDefaultSettingsUpdated(updatedDefaults: DefaultsMemento): Promise<void> {

        let generateOrders: boolean;
        try {
            generateOrders = await this.updatePositionQtyPrompt.show();
        } catch {
            return;
        }

        this.defaultsUpdated$.emit({memento: updatedDefaults, generateOrders});
    }

    @DetectMethodChanges()
    applyPositionsAfterStateAfterDefaultsUpdate(resolved: SolutionPositionDto[][]) {
        resolved.forEach(async (x, ix) => {
            const slot = ix === 0 ? 'first' : 'second';
            await this.applyPositionsAfterStateInternal(x, slot);
        });

        this.updateAtmDistance();
        this._hasChanges = true;
    }

    @DetectMethodChanges()
    applyManualAtmDistance() {

        if (this.underlying === 'SPX') {
            if (this.manualAtmDistance % 5 !== 0) {
                this._toastr.error('ATM Distance for SPX must be a multiple of 5');
                return;
            }
        }

        const diffToMove = this.atmDistance - this.manualAtmDistance;

        this._showAtmTextBox = false;

        if (diffToMove === 0) {
            return;
        }

        setTimeout(async () => {
            const direction = diffToMove > 0 ? 'up' : 'down';
            await this.shiftStrikes(direction, Math.abs(diffToMove));
        });
    }

    private focusManualAtmDistanceInput() {
        if (this.manualDistanceInput) {
            setTimeout(() => {
                this.manualDistanceInput.instance.focus();
            });
        }
    }

    manualAtmDistanceInputOnKeyUp(ev: any) {
        const obj = ev.event.originalEvent as KeyboardEvent;
        if (obj.key !== 'Escape') {
            return;
        }
        this.hideManualAtmDistanceInput();
    }

    @DetectMethodChanges()
    showManualAtmDistanceInput() {
        if (!isValidNumber(this.atmDistance)) {
            return;
        }

        this._showAtmTextBox = true;

        this.manualAtmDistance = this.atmDistance;

        if (this.showAtmTextBox) {
            this.focusManualAtmDistanceInput();
        }
    }

    hideManualAtmDistanceInput() {
        this.showAtmTextBox = false;
    }

    getExpirationColorRank(expiration: OptionExpirationDescriptor, position: BeforePositionModel) {

        if (isVoid(expiration)) {
            return 'dimgray';
        }

        const key = 'apgRank' + position.role + position.strategy;

        let allRanks = position.expirationsList.map(x => x[key]);

        const distinctRanks = Enumerable.from(allRanks)
            .distinct()
            .orderByDescending(x => x)
            .toArray();

        const currentRank: number = expiration[key];

        const ix = distinctRanks.indexOf(currentRank);

        const tenth = Math.ceil(distinctRanks.length * 0.15);

        if (ix <= tenth) {
            return undefined;
        }

        if (ix > tenth && ix <= tenth * 2) {
            return 'yellow';
        }

        return 'red';
    }

    private rankExpirations() {

        this.positionsData.forEach( (pd) => {

            pd.positions.forEach( (pos, ix) => {

                const key = 'apgRank' + pos.role + pos.strategy;

                let range = 10;

                if (pos.role.indexOf('Spread') < 0) {
                    range = 5;
                }

                if (pos.underlyingSymbol === 'SPX') {
                    range *= 10;
                }

                const strikeReverseDirection = isReversedCashFlowOrder(pos.strategy);
                const multiplier = strikeReverseDirection ? 1 : -1;

                let startStrike = pos.strike;
                if (!isValidNumber(startStrike, true)) {
                    if (pos.role !== 'ShortOption') {
                        const prevIx = strikeReverseDirection ? ix + 1 : ix - 1;

                        if (prevIx <= pd.positions.length && prevIx >= 0) {
                            const prevPos = pd.positions[prevIx];
                            startStrike = prevPos.strike;
                        }
                    }
                }

                const strikeStart = startStrike;
                const strikeEnd = startStrike + (range * multiplier);

                pos.expirationsList.forEach(x => {
                    const filteredStrikes = x.strikes
                        .filter(strike => {
                            const compareResult = numberCompare(strike, strikeStart) * multiplier;
                            return compareResult >= 0;
                        })
                        .filter(strike => {
                            const compareResult = numberCompare(strikeEnd, strike) * multiplier;
                            return compareResult >= 0;
                        });

                    if (!isValidNumber(strikeStart, true) || !isValidNumber(strikeEnd, true)) {
                        x[key] = 0;
                    } else {
                        x[key] = filteredStrikes.length;
                    }

                });
            });
        });
    }
}
