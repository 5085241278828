import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DetectMethodChanges, DxValueChanged, isVoid} from "../utils";
import {CallHedgePalette, PutHedgePalette} from "./hedge-palettes.const";

@Component({
    selector: 'ets-color-picker',
    templateUrl: 'color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorPickerComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef
    ) {
    }

    paletteVisible: boolean;

    @Input() selectedColor: string;

    @Output() selectedColorChange = new EventEmitter<string>();

    @Output() onValueChanged = new EventEmitter<DxValueChanged<string>>();

    @Input() disabled: boolean;

    private _pPal : string[] = PutHedgePalette;

    private _cPal = CallHedgePalette;

    get callPalette() : string[] {
        const p = this._cPal.filter(x => this.removeColors.indexOf(x) < 0);
        return p;
    }

    get putPalette() : string[] {
        const p = this._pPal.filter(x => this.removeColors.indexOf(x) < 0);
        return p;
    }

    @Input() showCallPalette = true;

    @Input() showPutPalette = true;

    @Input() width: any;

    @Input() removeColors: string[] = [];

    ngOnInit() {
    }

    @DetectMethodChanges()
    onPaletteButtonClicked() {
        this.paletteVisible = !this.paletteVisible;
    }

    @DetectMethodChanges()
    onPaletteHidden() {
        this.paletteVisible = false;
    }

    @DetectMethodChanges()
    onColorSelected(color: string) {
        this.selectedColor = color;
        this.selectedColorChange.emit(color);
        this.onPaletteHidden();
        this.onValueChanged.emit({value: color, event: 'color-selected'});
    }

    getColorBoxInputAttr() {
        if (isVoid(this.selectedColor)) {
            return {style: 'background: yellow;'}
        }

        return {style: 'background: inherit;'};
    }
}