import {Injectable} from '@angular/core';
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {ServiceConfiguration} from "../../adjustment-pricing-grid/services/ServiceConfiguration";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import * as Enumerable from "linq";
import {
    findHCF, getShortUUID,
    isValidNumber,
    isVoid,
    makeDayOfWeekDate,
    makeGuiFriendlyExpirationDate,
} from "../../utils";
import {HedgePosition} from "../data-model/hedge-position";
import {OptionsChainService} from "../../option-chains.service";
import {
    BucketRoleColor,
    GetOptionChainShellResponse,
    OptionExpirationDescriptor
} from "../../shell-communication/shell-dto-protocol";
import {BeforePositionDto} from "../../adjustment-pricing-grid/model/BeforePositionDto";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {makeOptionTicker, parseOptionTicker} from "../../options-common/options.model";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {HedgeData} from "./hedge-data";

export interface HedgeMatrixCellData {
    hedgeId: string;
    underlying: string;
    ticker: string;
    color: string;
    label: string;
    strike: number;
    expiration: string;
    optionType: 'Call' | 'Put';
    qty?: number;
    transQty?: number;
    outcomeQty?: number;
}

export interface HedgeMatrixTransLeg {
    ticker: string;
    qty: number;
    hedgeId: string;
}

@Injectable()
export class HedgeMatrixDataService {

    constructor(
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _apgDataService: ApgDataService,
        private readonly _lastQuoteCache: LastQuoteCacheService
    ) {
    }

    private _index: Record<number, Record<string, HedgeMatrixCellData>> = {};
    private _existingHedges: HedgeData[] = [];
    private _newHedges: HedgeData[] = [];
    private _activeExpiration: OptionExpirationDescriptor;
    private _portfolioPositionsByStrike: Record<number, string[]> = {};
    private _underlying: string;
    private _portfolioDefaultQty: number;
    private _chain: GetOptionChainShellResponse;
    private _strikes: number[];

    //  expiration: { strike: { hedge: [pnl] } }
    private _callExpirationPnl : Record<string, Record<number, Record<string, number>>> = {};
    private _putExpirationPnl : Record<string, Record<number, Record<string, number>>> = {}


    get activeExpiration(): OptionExpirationDescriptor {
        return this._activeExpiration;
    }

    async onPortfolioSelected(portfolio: ApgPortfolio): Promise<void> {

        this._existingHedges = [];
        this._newHedges = [];
        this._index = {};
        this._portfolioPositionsByStrike = {};
        this._activeExpiration = null;
        this._underlying = null;
        this._callExpirationPnl = {};
        this._putExpirationPnl = {};

        this.configure(portfolio.userId);

        this._underlying = await this._apgDataService.getUnderlyingOfPortfolio(portfolio);

        this._portfolioDefaultQty = await this._apgDataService.getDefaultQtyForPortfolio(portfolio);

        this._chain = await this._optionChainService.getChain(this._underlying)

        const hedgePositions = await this._hedgePositionsService
            .getHedgePositions(portfolio);

        const hedges = Enumerable.from(hedgePositions)
            .groupBy(x => x.groupId)
            .select(x => {
                const hedge: HedgeData = {
                    name: x.first().label,
                    id: x.key(),
                    color: x.first().color,
                    legs: x.toArray(),
                    type: x.first().type,
                    expiration: makeGuiFriendlyExpirationDate(x.first().expiration),
                    dayOfWeek: makeDayOfWeekDate(x.first().expiration)
                };
                return hedge;
            })
            .toArray();

        const sorted = hedges
            .sort((grpA, grpB) => {

                const grpAOrder = grpA.legs[0].groupOrder;
                const grpBOrder = grpB.legs[0].groupOrder;

                if (isValidNumber(grpAOrder) && isValidNumber(grpBOrder)) {
                    return grpAOrder - grpBOrder;
                } else {
                    const lastStrikeA = grpA.legs[grpA.legs.length - 1].strike;
                    const lastStrikeB = grpB.legs[grpB.legs.length - 1].strike;
                    return lastStrikeB - lastStrikeA;
                }
            });

        this._existingHedges = sorted;

        Enumerable.from(hedgePositions)
            .groupBy(x => x.strike)
            .forEach(x => {
                const strike = x.key();

                let container = this._index[strike];

                if (isVoid(container)) {
                    container = {};
                    this._index[strike] = container;
                }

                x.forEach(y => {
                    const cData: HedgeMatrixCellData = {
                        ticker: y.ticker,
                        underlying: y.asset,
                        hedgeId: y.groupId,
                        color: y.color,
                        label: y.label,
                        optionType: y.type,
                        strike: y.strike,
                        expiration: y.expiration,
                        qty: y.qty
                    }
                    container[y.groupId] = cData;
                });
            });


        const exp = await this.getActiveExpiration(hedgePositions[0]);

        this._activeExpiration = exp;

        const portfolioPositions = await this.getPortfolioPositions(portfolio);

        portfolioPositions
            .flatMap(x => x)
            .forEach(x => {

                const ticker = parseOptionTicker(x.ticker);

                const role = x.role;
                const strike = ticker!.strike;
                const color = BucketRoleColor[role];
                const type = ticker.type;

                let container = this._portfolioPositionsByStrike[strike];

                if (isVoid(container)) {
                    container = [];
                    this._portfolioPositionsByStrike[strike] = container;
                }

                if (container.indexOf(color) < 0) {
                    container.push(color);
                }
            });
    }

    get portfolioUnderlying() : string {
        return this._underlying;
    }

    getCellData(strike: number, hedgeId: string): HedgeMatrixCellData {
        let container = this._index[strike];

        if (isVoid(container)) {
            return null;
        }

        return container[hedgeId];
    }

    getLegStrikes(): number[] {
        const strikes = Object.keys(this._index).map(x => parseInt(x));
        return strikes;
    }

    getTotalQtyForStrike(strike: number, side: 'Call' | 'Put', visible: string[]): number {
        const container = this._index[strike];

        if (isVoid(container)) {
            return null;
        }


        const cellDatas = Object.values(container) as HedgeMatrixCellData[];

        const total = Enumerable.from(cellDatas)
            .where(x => x.optionType === side)
            .where(x => visible.indexOf(x.hedgeId) !== -1)
            .select(x => {
                const qty = x.qty || 0;
                const transQty = x.transQty || 0;
                const totalQty = qty + transQty;
                return totalQty;
            })
            .aggregate(0, (p, c) => p + c);

        return total;
    }

    getHedges(): HedgeData[] {
        return this._newHedges.concat(this._existingHedges);
    }

    getHedge(id: string) {
        const hedgeData = this.getHedges().find(x => x.id === id);
        return hedgeData;
    }

    getPortfolioLegColorByStrike(strike: number): string[] {
        const arr = this._portfolioPositionsByStrike[strike] || [];
        return arr.slice();
    }

    getPortfolioPositionStrikes(): number[] {
        return Object.keys(this._portfolioPositionsByStrike).map(x => parseInt(x));
    }

    getTickerForCell(strike: number, expiration: string, side: "Call" | "Put"): string {

        const expDescriptor = this._chain.expirations
            .find(x => x.optionExpirationDisplayDate
                .indexOf(expiration) >= 0);

        const ticker = makeOptionTicker(expDescriptor, side, strike);

        return ticker;

    }

    setTransQty(hedge: HedgeData, strike: number, transQty: number) {
        this.setQty(hedge, strike, 'transQty', transQty);
    }

    getTransQty(strike: number, hedgeId: string): number {
        return this.getQty(strike, hedgeId, 'transQty');
    }

    setOutcomeQty(hedge: HedgeData, strike: number, outcomeQty: number) {
        this.setQty(hedge, strike, 'outcomeQty', outcomeQty);
    }

    getOutcomeQty(strike: number, hedgeId: string): number {
        return this.getQty(strike, hedgeId, 'outcomeQty');
    }

    onHedgeModificationFinished(hedgeData: HedgeData) {
        Object.keys(this._index)
            .forEach(strike => {
                const container = this._index[strike];

                if (isVoid(container)) {
                    return;
                }

                const cellData = container[hedgeData.id];

                if (isVoid(cellData)) {
                    return;
                }

                cellData.transQty = cellData.outcomeQty = null;
            });
    }

    getTransCost(hedgeId: string): number {
        const hedgeTransCellData = Object.values(this._index)
            .map(x => x[hedgeId])
            .filter(x => !!x)
            .filter(x => isValidNumber(x.transQty, true));

        const qtties = hedgeTransCellData
            .filter(x => isValidNumber(x.transQty, true))
            .map(x => x.transQty);

        if (qtties.length  === 0) {
            return null;
        }

        const totalCost = hedgeTransCellData.map(x => {
            const transQty = x.transQty;
            const quote = this._lastQuoteCache.getLastQuote(x.ticker);
            const defaultQty = this._portfolioDefaultQty;

            const hcf = findHCF(qtties);

            let cost = quote?.mid * transQty / hcf;

            const ratio = hcf / defaultQty;

            if (isValidNumber(ratio, true)) {
                cost = cost * ratio;
            }

            return cost * -1;

        }).reduce((p, c) => {
            if (!isValidNumber(p) || !isValidNumber(c)) {
                return null;
            }
            return p + c;
        }, 0);

        return totalCost;
    }

    getOutcomeCost(hedgeId: string): number {
        const hedgeTransCellData = Object
            .values(this._index)
            .map(x => x[hedgeId])
            .filter(x => !!x)
            .filter(x => isValidNumber(x.outcomeQty, true));

        const qtties = hedgeTransCellData
            .filter(x => isValidNumber(x.outcomeQty, true))
            .map(x => x.outcomeQty);

        if (qtties.length === 0) {
            return null;
        }

        const totalCost = hedgeTransCellData.map(x => {
            const outcomeQty = x.outcomeQty;
            const quote = this._lastQuoteCache.getLastQuote(x.ticker);
            const defaultQty = this._portfolioDefaultQty;

            const hcf = findHCF(qtties);

            let cost = quote?.mid * outcomeQty / hcf;

            const ratio = hcf / defaultQty;

            if (isValidNumber(ratio, true)) {
                cost = cost * ratio;
            }

            return cost;

        }).reduce((p, c) => {
            if (!isValidNumber(p) || !isValidNumber(c)) {
                return null;
            }
            return p + c;
        }, 0);

        return totalCost;
    }

    onHedgeModificationStarted(hedgeData: HedgeData) {
        Object.keys(this._index)
            .forEach(strike => {
                const container = this._index[strike];

                if (isVoid(container)) {
                    return;
                }

                const cellData = container[hedgeData.id];

                if (isVoid(cellData)) {
                    return;
                }

                cellData.outcomeQty = cellData.qty;
            });
    }

    async addNewHedge(side: 'Call' | 'Put', underlying: string) : Promise<HedgeData> {

        const chain = await this._optionChainService.getChain(underlying)

        const expiration = this._activeExpiration || chain.expirations[0];

        const hedgeData: HedgeData = {
            id: getShortUUID(),
            isNew: true,
            type: side,
            name: `* New Hedge ${this._newHedges.length + 1} *`,
            color: undefined,
            dayOfWeek: makeDayOfWeekDate(expiration.optionExpirationDate),
            expiration: makeGuiFriendlyExpirationDate(expiration.optionExpirationDate),
            legs: []
        };

        this._newHedges.push(hedgeData);

        return hedgeData;
    }

    onNewHedgeExpirationChanged(hedgeId: string, expiration: string) {

        const hedgeData = this.getHedge(hedgeId);

        Object.keys(this._index).forEach(strike => {

            const container = this._index[strike];

            if (isVoid(container)) {
                return;
            }

            const cellData: HedgeMatrixCellData = container[hedgeId];

            if (isVoid(cellData)) {
                return;
            }

            cellData.expiration = expiration;
            cellData.ticker = null;

            if (isVoid(cellData.transQty)) {
                return;
            }

            const nStrike = parseInt(strike);

            const tickerForCell = this
                .getTickerForCell(nStrike, hedgeData.expiration, hedgeData.type);

            cellData.ticker = tickerForCell;
        });

        this.calculatePnls(this._strikes);
    }

    removeHedge(hedgeId: string) {
        const ix = this._newHedges.findIndex(x => x.id === hedgeId);
        if (ix === -1) {
            return;
        }
        this._newHedges.splice(ix, 1);
    }

    getTransactionLegs(hedgeId: string) : HedgeMatrixTransLeg[] {
        const legs = Object
            .values(this._index)
            .map(x => x[hedgeId])
            .filter(x => !!x)
            .filter(x => isValidNumber(x.transQty, true))
            .map(x => {

                const ticker = x.ticker;
                const transQty = x.transQty;

                return {
                    hedgeId,
                    ticker,
                    qty: transQty,
                }
            });

        return legs;
    }

    getOutcomeLegs(hedgeId: string) : HedgeMatrixTransLeg[] {
        const legs = Object
            .values(this._index)
            .map(x => x[hedgeId])
            .filter(x => !!x)
            .filter(x => isValidNumber(x.outcomeQty, true))
            .map(x => {

                const ticker = x.ticker;
                const outcomeQty = x.outcomeQty;

                return {
                    hedgeId,
                    ticker,
                    qty: outcomeQty,
                }
            });

        return legs;
    }

    calculatePnls(strikes: number[]) {
        this._strikes = strikes;
        this._callExpirationPnl = {};
        this._putExpirationPnl = {};
        this.calculateExpirationPnls('Call', strikes, this._callExpirationPnl);
        this.calculateExpirationPnls('Put', strikes, this._putExpirationPnl);
    }

    getExpirationPnl(expiration: string, strike: number, side: 'Call'|'Put', visible: string[]) {

        const index = side === 'Call'
            ? this._callExpirationPnl
            : this._putExpirationPnl;


        const expContainer = index[expiration];

        if (isVoid(expContainer)) {
            return null;
        }

        const strikeContainer = expContainer[strike];

        if (isVoid(strikeContainer)) {
            return null;
        }

        const numbers = Object.keys(strikeContainer).filter(x => visible.indexOf(x) !== -1)
            .map(x => strikeContainer[x]);

        const total = numbers.reduce((p,c) => p + c, 0);

        return total;
    }

    private calculateExpirationPnls(side: 'Call' | 'Put', strikes: number[], index: Record<string, Record<number, Record<string, number>>>) {
        this.getHedges()
            .filter(x => x.type === side)
            .forEach(hedge => {
                strikes.forEach((strike) => {

                    const hedgeId = hedge.id;

                    const hedgeExpiration = hedge.expiration;

                    const hedgeIsNew = hedge.isNew;

                    let hedgeLegs = hedge.legs.slice();

                    if (hedgeIsNew) {
                        const transactionLegs = this
                            .getTransactionLegs(hedgeId)
                            .map(x => {
                                const optionTicker = parseOptionTicker(x.ticker);

                                const hp : HedgePosition = {
                                    qty: x.qty,
                                    ticker: x.ticker,
                                    groupId: x.hedgeId,
                                    type: optionTicker?.type,
                                    strike: optionTicker?.strike
                                };

                                return hp;
                            });

                        hedgeLegs = transactionLegs;

                        hedge.legs = hedgeLegs;
                    } else {

                        const outcomeLegs = this.getOutcomeLegs(hedgeId);

                        if (outcomeLegs.length > 0) {
                            const positions = outcomeLegs.map(x => {

                                const optionTicker = parseOptionTicker(x.ticker);

                                const hp: HedgePosition = {
                                    qty: x.qty,
                                    ticker: x.ticker,
                                    groupId: x.hedgeId,
                                    type: optionTicker?.type,
                                    strike: optionTicker?.strike
                                };

                                return hp;

                            });

                            hedgeLegs = positions;
                        }
                    }

                    const legPnls = hedgeLegs
                        .map(leg => {

                            // const field = hedge.isNew ? 'transQty' : 'outcomeQty';
                            const field = 'outcomeQty';

                            let outcomeQty = this.getQty(leg.strike, hedgeId, field);

                            if (!isValidNumber(outcomeQty)) {
                                outcomeQty = leg.qty;
                            }

                            let delta = (strike - leg.strike) * outcomeQty;

                            if (leg.type === 'Put') {
                                delta *= -1;
                            }

                            if (delta < 0) {
                                if (outcomeQty > 0) {
                                    delta = 0;
                                }
                            } else if  (delta > 0) {
                                if (outcomeQty < 0) {
                                    delta = 0;
                                }
                            }

                            return delta * 100;

                        })
                        .reduce( (p,c) => p + c, 0);

                    let expContainer = index[hedgeExpiration];

                    if (isVoid(expContainer)) {
                        expContainer = {};
                        index[hedgeExpiration] = expContainer;
                    }

                    let strikeContainer = expContainer[strike];

                    if (isVoid(strikeContainer)) {
                        strikeContainer = {};
                        expContainer[strike] = strikeContainer;
                    }

                    strikeContainer[hedgeId] = legPnls;
                });
            });
    }

    private getQty(strike: number, hedgeId: string, field: keyof HedgeMatrixCellData): number {
        const container = this._index[strike];

        if (isVoid(container)) {
            return null;
        }

        const cellData = container[hedgeId];

        if (isVoid(cellData)) {
            return null;
        }

        return cellData[field] as number;
    }

    private setQty(hedge: HedgeData, strike: number, field: keyof HedgeMatrixCellData, qty: number) {
        try {
            this.setQtyInternal(hedge, strike, field, qty);
        } finally {
            if (!isVoid(this._strikes)) {
                this.calculatePnls(this._strikes);
            }

        }
    }

    private setQtyInternal(hedge: HedgeData, strike: number, field: keyof HedgeMatrixCellData, qty: number) {
        let container = this._index[strike];

        if (isVoid(container)) {
            container = {};
            this._index[strike] = container;
        }

        let cellData = container[hedge.id];

        if (isVoid(cellData)) {

            cellData = {
                hedgeId: hedge.id,
                optionType: hedge.type,
                label: hedge.name,
                color: hedge.color,
                expiration: hedge.expiration,
                strike: strike,
                underlying: null,
                ticker: null
            };

            const tickerForCell = this
                .getTickerForCell(strike, hedge.expiration, hedge.type);

            cellData.ticker = tickerForCell;

            // @ts-ignore
            cellData[field] = qty;

            container[hedge.id] = cellData;
        } else {
            // @ts-ignore
            cellData[field] = qty;
        }

        const legQty = cellData.qty || 0;

        if (!isValidNumber(qty)) {
            if (field === 'transQty') {
                cellData.transQty = null;
                cellData.outcomeQty = legQty || null;
            } else if (field === 'outcomeQty') {
                if (legQty !== 0) { // existing leg
                    cellData.transQty = -legQty;
                    cellData.outcomeQty = null;
                } else { // deleting just created leg
                    cellData.transQty = null;
                    cellData.outcomeQty = legQty || null;
                }
            }

            return;
        }

        if (field === 'outcomeQty') {
            const outcomeQty = cellData.outcomeQty || 0;
            const trans = (outcomeQty - legQty) || null;
            cellData.transQty = trans;
        } else {
            const transQty = cellData.transQty || 0;
            const outcome = (legQty + transQty) || null;
            cellData.outcomeQty = outcome;
        }
    }

    private async getPortfolioPositions(portfolio: ApgPortfolio): Promise<BeforePositionDto[][]> {
        const positions = await this._apgDataService.getPortfolioPositions(portfolio);
        return positions;
    }

    private async getActiveExpiration(hedgePosition: HedgePosition): Promise<OptionExpirationDescriptor> {

        if (isVoid(hedgePosition)) {
            return undefined;
        }

        const chain = await this._optionChainService.getChain(hedgePosition.asset);

        if (isVoid(chain)) {
            return undefined;
        }

        const exp = chain.expirations.find(x => x.optionExpirationDate === hedgePosition.expiration);

        return exp;
    }

    private configure(userId: string): void {
        const cfg: ServiceConfiguration = {
            userId
        };

        this._hedgePositionsService.configure(cfg);
    }
}