export const PutHedgePalette : string[] = [
    '#c7adad',
    '#b07c7c',
    '#9d4c4c',
    '#873434',

];


export const CallHedgePalette : string[] =  [
    '#a9bccc',
    '#7f9bb3',
    '#547999',
    '#3f83ea',
];