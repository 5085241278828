import { Injectable } from '@angular/core';
import { StrategiesService } from './strategies/strategies.service';
import { isNullOrUndefined, isTruthy } from './utils';
import { MessageBusService } from './message-bus.service';
import { StrategyParametersUpdatedDto } from './shell-communication/dtos/strategy-parameters-updated-dto.class';
import { IANAZone, DateTime } from 'luxon';
import { defaultTimestampFormat } from './ag-grid-contrib';
import { ApplicationSettingsService } from './app-settings/application-settings.service';


@Injectable({ providedIn: 'root' })
export class TimestampsService {
   constructor(
      private readonly _strategiesService: StrategiesService,
      private readonly _messageBus: MessageBusService,
      private readonly _applicationSettings: ApplicationSettingsService
   ) {
      this._messageBus.of<StrategyParametersUpdatedDto>('StrategyParametersUpdatedDto')
         .subscribe(
            x => this._onStrategyParametersUpdated(x.payload)
         );
   }

   private _timezoneByStrategyCache: { [ix: string]: string } = {};
   private readonly _timeZonesCache: { [ix: string]: IANAZone } = {};

   getDefaultTimezone(defaultValue?: string): string {
      let timeZone = this._applicationSettings.defaultTimezone;
      return timeZone || defaultValue;
   }

   getZonedFormattedDateTime(timestamp: Date, format: string): string {
      
      if (isNullOrUndefined(timestamp)) {
         return '';
      }

      const tz = this.getDefaultTimezone('UTC');

      const dt = DateTime.fromJSDate(timestamp);
      const zonedDt = dt.setZone(tz);
      const frmt = zonedDt.toFormat(format);
      
      return frmt;
   }

   getDefaultTimezoneOffset(timestamp: Date): string {
      const tz = this.getDefaultTimezone();
      if (!tz) {
         return null;
      }

      return this._getIANAZoneOffset(tz, timestamp);
   }

   getStrategyTimezone(strategyId: string): string {

      const tzFromCache = this._timezoneByStrategyCache[strategyId];

      if (isTruthy(tzFromCache)) {
         return tzFromCache;
      }

      const strategy = this._strategiesService.getById(strategyId);

      let timeZone;

      if (strategy) {
         // tslint:disable-next-line: no-string-literal
         const strategyZone = strategy.parameters['timestampszone'];
         if (strategyZone) {
            timeZone = strategyZone;
         }
      }

      if (!timeZone) {
         timeZone = this.getDefaultTimezone('UTC');
      }

      this._timezoneByStrategyCache[strategyId] = timeZone;

      return timeZone;
   }

   getIANAZoneOffsetForNgFormatting(strategyId: string, timestamp: Date): string {
      const tz = this.getStrategyTimezone(strategyId);

      if (!tz) {
         return null;
      }

      return this._getIANAZoneOffset(tz, timestamp);
   }

   getFormattedDateTimeForStrategy(strategyId: string, timestamp: Date): string {
      const tz = this.getStrategyTimezone(strategyId);
      const frmt = DateTime.fromJSDate(timestamp).setZone(tz).toFormat(defaultTimestampFormat);
      return frmt;
   }

   getDefaultFormattedDateTime(timestamp: (Date | string)): string {
      const tz = this.getDefaultTimezone('UTC');

      const dt =  typeof timestamp === 'string'
          ? DateTime.fromISO(timestamp)
          :  DateTime.fromJSDate(timestamp);
      const zonedDt = dt.setZone(tz);
      const frmt = zonedDt.toFormat(defaultTimestampFormat);
      return frmt;
   }

   getDefaultShortFormattedTime(timestamp: Date): string {
      const tz = this.getDefaultTimezone('UTC');
      const frmt = DateTime.fromJSDate(timestamp).setZone(tz).toFormat('hh:mm:ss.SSS a');
      return frmt;
   }

   getDefaultDateTime(timestamp: number): number {
      const tz = this.getDefaultTimezone('UTC');
      const jsDate = DateTime.fromMillis(timestamp).setZone(tz).toMillis();
      return jsDate;
   }
   
   getZonedDateTime(timestamp: number): Date {
      const tz = this.getDefaultTimezone('UTC');
      const jsDate = DateTime.fromMillis(timestamp).setZone(tz).toJSDate();
      return jsDate;
   }

   private _getIANAZoneOffset(tz: string, timestamp: Date): string {
      if (!(tz in this._timeZonesCache)) {
         const z = IANAZone.create(tz);
         this._timeZonesCache[tz] = z;
      }

      const ianaZone = this._timeZonesCache[tz];

      return ianaZone.formatOffset(timestamp.getUTCMilliseconds(), 'techie');
   }

   private _onStrategyParametersUpdated(msg: StrategyParametersUpdatedDto) {
      delete this._timezoneByStrategyCache[msg.strategy.strategyId];
   }
}
