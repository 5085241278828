<div [ngClass]="{'ets-hidden': !isActive}" class="panel-container">
  
  <div class="container">
    <div class="sortable-list">
      <ul id="sortable">
        <li *ngFor="let task of this.tasks" draggable="true" (dragstart)="this.onItemDragStart($event)"
            (dragend)="this.onItemDragEnd($event)" > {{ task.text }} </li>
      </ul>
    </div>
  </div>
  
</div>

